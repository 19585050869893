import { FC, ChangeEvent, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  useAppDispatch,
  useAppSelector,
  requestSigning,
  dashboard,
} from "store";
import {
  setFileName,
  setFilesToSave,
  setIsAddFileModal,
  setFilesLimit,
} from "store/requestSigning";

import { Button, Icon, Input, Modal } from "components/UI";
import {
  DocImage,
  RecipientsUploadFile,
  RecipientsAddFileModal,
} from "components";
import { useSigningsRequest } from "hooks";
import { toBase64 } from "utils";

import styles from "./styles.module.scss";

export const RecipientsFile: FC = () => {
  const dispatch = useAppDispatch();
  const { filesToSave, fileName, isAddFileModal, filesLimit, editedDraft } =
    useAppSelector(requestSigning);
  const { openedDocument } = useAppSelector(dashboard);
  const { filesChanged, setEditedFile } = useSigningsRequest();
  const { t } = useTranslation("RequestSigning", {
    keyPrefix: "RecipientsFile",
  });
  const [isEditName, setIsEditName] = useState<boolean>(false);
  const [stateFileUrl, setStateFileUrl] = useState<
    { title: string; url: string }[]
  >([]);

  useEffect(() => {
    setEditedFile();

    return setStateFileUrl([]);
  }, [setEditedFile]);

  useEffect(() => {
    if (filesToSave.length > 1) {
      dispatch(setFileName(`${filesToSave[0].title} Merged`));
    } else if (filesToSave.length > 0) {
      dispatch(setFileName(filesToSave[0].title));
    }
  }, [dispatch, filesToSave]);

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 80) return;
    dispatch(setFileName(e.target.value));
  };

  const handleClearFile = (index: number) => {
    const newArr = filesToSave.slice();
    const removedFile = newArr.splice(index, 1);
    dispatch(setFilesToSave(newArr));
    dispatch(setFilesLimit(filesLimit + removedFile[0].size));
  };

  const handleOpenAddFileModal = () => {
    dispatch(setIsAddFileModal(true));
  };

  const handleCloseAddFileModal = () => {
    dispatch(setIsAddFileModal(false));
  };

  const handleLeaveName = () => {
    setIsEditName(false);
    if (!fileName) {
      dispatch(setFileName(filesToSave[0].title));
    }
  };

  const getStateFileImage = useCallback(async () => {
    if (openedDocument?.url) {
      const url = openedDocument.previewUrl || "";
      const title = openedDocument.name;
      setStateFileUrl((prevState) => [
        ...prevState.filter((item) => item.title !== title),
        { title, url },
      ]);
    } else if (filesToSave.length > 0) {
      filesToSave
        .filter((el) => el.size > 0)
        .forEach(async (el) => {
          const url = await toBase64(el.data);
          if (typeof url === "string") {
            setStateFileUrl((prevState) => [
              ...prevState.filter((item) => item.title !== el.title),
              { title: el.title, url },
            ]);
          }
        });
    }
  }, [filesToSave, openedDocument]);

  useEffect(() => {
    getStateFileImage();
  }, [getStateFileImage]);

  return (
    <div className={styles.RecipientsFile}>
      <Modal isShowed={isAddFileModal}>
        <RecipientsAddFileModal onClose={handleCloseAddFileModal} />
      </Modal>
      <h2 className={styles.title}>{t("title")}</h2>
      {filesToSave.length > 0 ? (
        <div className={styles.uploadedFiles}>
          {filesToSave.length > 1 && (
            <Input
              value={fileName}
              onChange={handleChangeName}
              onBlur={handleLeaveName}
              label={t("inputNameLabel")}
              isRequired
              className={styles.fileTitle}
            />
          )}
          {filesToSave.map((el, index) => (
            <div className={styles.uploadedFile} key={`${el.title}${index}`}>
              <DocImage
                type="PDF"
                previewUrl={
                  editedDraft && !filesChanged
                    ? editedDraft.previewFile?.url
                    : stateFileUrl.find((item) => el.title === item.title)?.url
                }
                isPdf={
                  stateFileUrl?.length > (editedDraft?.previewFile ? 1 : 0) &&
                  !openedDocument?.url
                }
              />
              {filesToSave.length === 1 && isEditName && (
                <Input
                  value={fileName}
                  onChange={handleChangeName}
                  onBlur={handleLeaveName}
                  className={styles.fileInput}
                  isBoundless
                />
              )}
              {(filesToSave.length > 1 ||
                (!isEditName && filesToSave.length === 1)) && (
                <>
                  <div
                    className={styles.fileTitle}
                    onClick={() => setIsEditName(true)}
                  >
                    {filesToSave.length === 1 ? fileName : el.title}
                  </div>
                  <Icon
                    name="close-in-circle"
                    action={() => handleClearFile(index)}
                    className={styles.clearIcon}
                  />
                </>
              )}
            </div>
          ))}
          <Button
            variant="textBlack"
            size="sm"
            title={t("addButtonTitle")}
            onClick={handleOpenAddFileModal}
            iconStart="add-in-circle"
            iconStartType="stroke"
          />
        </div>
      ) : (
        <RecipientsUploadFile />
      )}
    </div>
  );
};
