import { FC, ChangeEvent, useState, useEffect } from "react";
import { isAxiosError } from "axios";
import { useDebouncedCallback } from "use-debounce";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, requestSigning, auth } from "store";
import { setRecipients } from "store/requestSigning";
import { getContacts } from "store/contacts/thunks";

import { Icon, Input, Button } from "components/UI";
import { Contacts } from "api";
import { toastError, validateEmail, cs } from "utils";
import { IContact } from "types";
import { useEffectOnce, useSigningsRequest } from "hooks";

import styles from "./styles.module.scss";

export const RecipientsList: FC = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(auth);
  const { recipients, editedDraft } = useAppSelector(requestSigning);
  const { updateContacts } = useSigningsRequest();
  const { t } = useTranslation("RequestSigning", {
    keyPrefix: "RecipientsList",
  });
  const { t: tG } = useTranslation("General");
  const [suggestionsFor, setSuggestionsFor] = useState<string>("");
  const [contactsList, setContactsList] = useState<IContact[]>([]);

  const handleSearchSuggestions = useDebouncedCallback(
    async (value: string) => {
      if (value) {
        try {
          const res = await Contacts.searchContact({ query: value });
          if (res?.items) {
            setContactsList(
              res.items.filter(
                (item) => !recipients.some((el) => el.email === item.email),
              ),
            );
          }
        } catch (error) {
          console.log("error:", error);
          if (isAxiosError(error)) {
            error?.message &&
              toastError(
                Array.isArray(error.message) ? error.message[0] : error.message,
              );
          }
        }
      }
    },
    500,
  );

  useEffectOnce(() => {
    dispatch(getContacts());
  });

  useEffect(() => {
    if (editedDraft) {
      dispatch(setRecipients(editedDraft.recipients));
    }
  }, [dispatch, editedDraft]);

  const checkEmailValidity = (index: number) => {
    const res = [...recipients];
    const emailValidationErrors =
      res[index].email && validateEmail(res[index].email);

    if (emailValidationErrors) {
      res[index] = {
        ...res[index],
        error: res[index].error || emailValidationErrors[0],
      };
    }

    if (res[index].email === user?.email) {
      res[index] = {
        ...res[index],
        error: t("emailOwnerError"),
      };
    }
    dispatch(setRecipients(res));
  };

  const handleChange = (
    { target }: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (target.value?.length > 100) return;
    const res = [...recipients];
    res[index] = { ...res[index], [target.name]: target.value };
    if (
      target.name === "email" &&
      recipients.some((el) => el.email === target.value)
    ) {
      res[index] = {
        ...res[index],
        error: t("recipientError"),
      };
    } else if (target.name === "email") {
      res[index] = {
        ...res[index],
        error: "",
      };
    }
    setSuggestionsFor(`${target.name}${index}`);
    handleSearchSuggestions(target.value);
    dispatch(setRecipients(res));
  };

  const handleAddRecipient = () => {
    updateContacts(true);
  };

  const handleRemoveRecipient = (index: number) => {
    const res = [...recipients];
    res.splice(index, 1);
    dispatch(setRecipients(res || []));
  };

  const handleCloseSuggestions = () => {
    setContactsList([]);
    setSuggestionsFor("");
  };

  const handleSelectContact = (
    { id, firstName, lastName, email }: IContact,
    index: number,
  ) => {
    const res = [...recipients];
    res[index] = {
      id,
      firstName,
      lastName,
      email,
      error: "",
    };
    dispatch(setRecipients(res));
    handleCloseSuggestions();
  };

  const renderSuggestions = (index: number) => {
    if (contactsList.length > 0) {
      return (
        <OutsideClickHandler onOutsideClick={handleCloseSuggestions}>
          <div className={styles.suggestions}>
            {contactsList.map((item) => (
              <div
                key={item.id}
                className={styles.contact}
                onClick={() => handleSelectContact(item, index)}
              >
                <div className={styles.name}>
                  {item.firstName} {item.lastName}
                </div>
                <div className={styles.email}>{item.email}</div>
              </div>
            ))}
          </div>
        </OutsideClickHandler>
      );
    }
  };

  return (
    <div className={styles.RecipientsList}>
      <h2 className={styles.title}>{t("title")}</h2>

      {recipients.map((item, index) => (
        <div className={styles.inputsWrap} key={index}>
          <div className={cs([styles.inputWrap, styles.plural])}>
            <div className={styles.inputWrap}>
              <Input
                className={styles.input}
                onChange={(e) => handleChange(e, index)}
                value={item.firstName}
                name="firstName"
                placeholder={t("inputFirstNamePlaceholder")}
                label={t("inputFirstNameLabel")}
                autoComplete="off"
                isRequired
              />
              {suggestionsFor === `firstName${index}` &&
                renderSuggestions(index)}
            </div>
            <div className={styles.inputWrap}>
              <Input
                className={styles.input}
                onChange={(e) => handleChange(e, index)}
                value={item.lastName}
                name="lastName"
                placeholder={t("inputLastNamePlaceholder")}
                label={t("inputLastNameLabel")}
                autoComplete="off"
                isRequired
              />
              {suggestionsFor === `lastName${index}` &&
                renderSuggestions(index)}
            </div>
          </div>
          <div className={styles.inputWrap}>
            <Input
              className={styles.input}
              onChange={(e) => handleChange(e, index)}
              onBlur={() => checkEmailValidity(index)}
              value={item.email}
              name="email"
              placeholder={tG("emailPlaceholder")}
              label={tG("emailLabel")}
              autoComplete="off"
              error={item.error}
              isRequired
            />
            {suggestionsFor === `email${index}` && renderSuggestions(index)}
          </div>
          {/* {index > 0 && ( */}
          <div
            className={styles.clearButton}
            onClick={() => handleRemoveRecipient(index)}
          >
            <Icon name="close" size={12} />
          </div>
          {/* )} */}
        </div>
      ))}
      {recipients?.length <= 10 && (
        <Button
          className={styles.addRecipientButton}
          size="sm"
          variant="textBlack"
          title={t("addRecipientButtonTitle")}
          iconStart="add-recepient"
          iconStartType="stroke"
          onClick={handleAddRecipient}
        />
      )}
    </div>
  );
};
