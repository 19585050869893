import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Icon, Button, Spinner, CircleProgressBar } from "components/UI";
import { palette, cs, getRootFolderName } from "utils";
import { File } from "types";

import styles from "./styles.module.scss";

type UploadModalProps = {
  onClose: () => void;
  onCancel: (file: File) => void;
  onCancelAll: () => void;
  onToggle: () => void;
  files: File[];
  isFullView: boolean;
};

export const UploadModal: FC<UploadModalProps> = ({
  onClose,
  onCancel,
  onCancelAll,
  onToggle,
  files,
  isFullView,
}) => {
  const { t } = useTranslation("Modals", { keyPrefix: "Upload" });
  const isSomeUploading = files.some((item) => item.status === "");

  return (
    <div className={styles.UploadModal}>
      <div className={styles.container}>
        <header className={cs([styles.header, isFullView && styles.fullView])}>
          {isSomeUploading ? (
            <Spinner size={24} className={styles.statusIcon} />
          ) : (
            <Icon
              name="done-success"
              className={styles.statusIcon}
              color={palette.green}
            />
          )}
          <div className={styles.text}>
            <h2
              className={cs([styles.title, !isSomeUploading && styles.success])}
            >
              {isSomeUploading ? t("titleInProgress") : t("titleFinished")}
            </h2>
            <p className={styles.info}>
              {t("itemsUploaded", {
                ready: files.filter((el) => el.status === "success").length,
                total: files.length,
              })}
            </p>
          </div>
          <Icon
            name="chevron-down-empty"
            className={cs([styles.arrow, !isFullView && styles.up])}
            size={18}
            action={onToggle}
          />
          {isSomeUploading ? (
            <Button
              onClick={onCancelAll}
              title={t("cancelAllButtonTitle")}
              variant="textBlack"
              size="sm"
            />
          ) : (
            <Icon name="close" className={styles.close} action={onClose} />
          )}
        </header>
        <ul className={cs([styles.files, isFullView && styles.fullView])}>
          {files
            .sort((a, b) => (a.order > b.order ? -1 : 1))
            .map((el, index) => {
              const isUploading = el.status === "";
              return (
                <li key={index} className={styles.file}>
                  <Icon name="file" />
                  <p className={styles.documentTitle}>{el.title}</p>
                  <div className={styles.directory}>
                    to {getRootFolderName(el.dir)}
                  </div>
                  <div
                    className={cs([
                      styles.status,
                      (!isUploading || el.status !== "canceled") &&
                        styles.success,
                    ])}
                  >
                    {isUploading && el.status !== "canceled" && (
                      <>
                        <CircleProgressBar />
                        <Button
                          onClick={() => onCancel(el)}
                          title={t("cancelButtonTitle")}
                          variant="textBlack"
                          size="sm"
                        />
                      </>
                    )}
                    {!isUploading && el.status === "success" && (
                      <>
                        <Icon
                          name="done-success"
                          color={palette.green}
                          size={15}
                        />
                        <p className={styles.success}>{t("uploaded")}</p>
                      </>
                    )}
                    {!isUploading && el.status === "error" && (
                      <>
                        <Icon
                          name="warning-error"
                          color={palette.red}
                          size={15}
                        />
                        <p className={styles.error}>{t("uploadFailed")}</p>
                      </>
                    )}
                    {el.status === "canceled" && (
                      <>
                        <Icon
                          name="close-in-circle"
                          color={palette.red}
                          size={15}
                        />
                        <p className={styles.error}>{t("canceled")}</p>
                      </>
                    )}
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};
