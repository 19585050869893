import { FC } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, contacts } from "store";
import {
  setIsContactModal,
  setIsDeleteItemsModal,
  setEditableContact,
  setSelectedItems,
} from "store/contacts";
import { deleteContacts } from "store/contacts/thunks";

import { ContactModal, AreYouSureModal } from "components";
import { Modal } from "components/UI";

export const ContactsTableModals: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Table", {
    keyPrefix: "Contacts.Modals",
  });
  const { isContactModal, isDeleteItemsModal, itemsToDelete } =
    useAppSelector(contacts);
  const isPlural = itemsToDelete.length > 1;

  const handleCloseModal = () => {
    dispatch(setIsContactModal(false));
    dispatch(setEditableContact(""));
  };

  const handleCloseAreYouSureDelete = () => {
    dispatch(setIsDeleteItemsModal(false));
  };

  const handleConfirmAreYouSureDelete = () => {
    dispatch(deleteContacts({ contactIds: itemsToDelete }));
    dispatch(setSelectedItems([]));
    dispatch(setIsDeleteItemsModal(false));
  };

  return (
    <>
      <Modal isShowed={isContactModal} onClose={handleCloseModal}>
        <ContactModal onClose={handleCloseModal} />
      </Modal>
      <Modal
        isShowed={isDeleteItemsModal}
        onClose={handleCloseAreYouSureDelete}
      >
        <AreYouSureModal
          onClose={handleCloseAreYouSureDelete}
          onSubmit={handleConfirmAreYouSureDelete}
          info={isPlural ? t("infoPlural") : t("info")}
          confirmButtonTitle={
            isPlural ? t("confirmButtonTitlePlural") : t("confirmButtonTitle")
          }
        />
      </Modal>
    </>
  );
};
