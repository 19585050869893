import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IAuthUser } from "types";
import { IDeleteReason } from "api";
import { getUser, getDeleteReasons, updatedUserInfo } from "./thunks";

export type SettingsState = {
  verificationId: string;
  passwordVerificationId: string;
  email: string;
  tempPass: string;
  user: IAuthUser | null;
  deleteReasons: IDeleteReason[];
  isFromSettings: boolean;
};

const initialState: SettingsState = {
  verificationId: "",
  passwordVerificationId: "",
  email: "",
  tempPass: "",
  user: null,
  deleteReasons: [],
  isFromSettings: false,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setVerificationId(state, { payload }: PayloadAction<string>) {
      state.verificationId = payload;
    },
    setPasswordVerificationId(state, { payload }: PayloadAction<string>) {
      state.passwordVerificationId = payload;
    },
    setEmail(state, { payload }: PayloadAction<string>) {
      state.email = payload;
    },
    setTempPass(state, { payload }: PayloadAction<string>) {
      state.tempPass = payload;
    },
    setIsFromSettings(state, { payload }: PayloadAction<boolean>) {
      state.isFromSettings = payload;
    },
    setUser(state, { payload }: PayloadAction<IAuthUser | null>) {
      state.user = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, action) => {
      if (action.payload) {
        state.user = action.payload;
      }
    });
    builder.addCase(updatedUserInfo.fulfilled, (state, action) => {
      if (action.payload) {
        state.user = action.payload;
      }
    });
    builder.addCase(getDeleteReasons.fulfilled, (state, action) => {
      if (action.payload) {
        state.deleteReasons = action.payload.reasons;
      }
    });
  },
});

export const {
  setVerificationId,
  setEmail,
  setPasswordVerificationId,
  setTempPass,
  setIsFromSettings,
  setUser,
} = settingsSlice.actions;

export default settingsSlice.reducer;
