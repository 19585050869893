import { AxiosError, isAxiosError } from "axios";
import queryString from "query-string";

import { toastError, dashboardErrors } from "utils";
import {
  IHttpRejection,
  IDraft,
  IDetailedDraft,
  IEditedDraft,
  IMeta,
  ISentDoc,
  IInboxDoc,
  IInboxDocDetailed,
  IActionHistoryItem,
  IOpenedDocumentMetadata,
} from "types";

import { $http } from "./instance";

interface IRecipientOriginal {
  firstName: string;
  lastName: string;
  email: string;
}
interface IGetDraftsRequest {
  orderBy?: string;
  order?: string;
}

interface IGetDraftsResponse {
  items: IDraft[];
}

interface IGetDraftRequest {
  id: string;
}

interface ICreateDraftRequest {
  name: string;
  file: {
    sourceDocumentId?: string;
    fileSize?: number;
  };
  recipients: IRecipientOriginal[];
}

interface IUpdateDraftRequest {
  name?: string;
  file?: {
    sourceDocumentId?: string;
    fileSize?: number;
  };
  recipients?: IRecipientOriginal[];
  instantJson?: any;
}
interface ISendDraftEmailRequest {
  emailSubject: string;
  comments?: string;
}

export interface IDeleteDraftsRequest {
  documentIds: string[];
}

interface IDeleteDraftsResponse {
  message: string;
}

export interface IGetSentDocsRequest {
  orderBy?: "name" | "modified_at" | "sent_at";
  order?: "DESC" | "ASC";
  page?: number;
  limit?: number;
  filter?: "in_progress" | "completed" | "voided" | "all";
}

interface IGetSentDocsResponse {
  meta: IMeta;
  items: ISentDoc[];
}

interface IDownloadSentDocResponse {
  id: string;
  name: string;
  file: {
    fileSize: number;
    url: string;
    urlExpiresAt: string;
    isUploaded: boolean;
  };
}
export interface IGetInboxDocsRequest {
  orderBy?: "name" | "received_at";
  order?: "DESC" | "ASC";
  page?: number;
  limit?: number;
  filter?:
    | "need_to_sign"
    | "waiting_for_others"
    | "completed"
    | "voided"
    | "all";
}

interface IGetInboxDocsResponse {
  meta: IMeta;
  items: IInboxDoc[];
}

interface IDownloadInboxDocResponse {
  id: string;
  name: string;
  file: {
    fileSize: number;
    url: string;
    urlExpiresAt: string;
    isUploaded: boolean;
  };
}

interface ISignInboxDocRequest {
  instantJson: any;
  signatureFile: string;
}

export interface IRejectInboxDocRequest {
  id: string;
  reason?: string;
}

export interface IUndoDeleteDraftRequest {
  documentIds: string[];
}
interface IUndoDeleteDraftResponse {
  message: string;
}
interface IGetActionsHistoryResponse {
  id: string;
  items: IActionHistoryItem[];
}

export const SharedDocuments = {
  getDrafts: async ({
    orderBy,
    order,
  }: IGetDraftsRequest): Promise<IGetDraftsResponse | undefined> => {
    try {
      const query = queryString.stringify(
        { orderBy, order },
        { skipEmptyString: true },
      );
      const res = await $http.get(`/clients/shared-documents/drafts?${query}`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  getDraft: async ({
    id,
  }: IGetDraftRequest): Promise<IDetailedDraft | undefined> => {
    try {
      const res = await $http.get(`/clients/shared-documents/drafts/${id}`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  createDraft: async (
    data: ICreateDraftRequest,
  ): Promise<IEditedDraft | undefined> => {
    try {
      const res = await $http.post(`/clients/shared-documents`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  updateDraft: async (
    id: string,
    data: IUpdateDraftRequest,
  ): Promise<IEditedDraft | undefined> => {
    try {
      const res = await $http.put(
        `/clients/shared-documents/drafts/${id}`,
        data,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  deleteDraft: async (
    data: IDeleteDraftsRequest,
  ): Promise<IDeleteDraftsResponse | undefined> => {
    try {
      // const isPlural = data.documentIds.length > 1;
      const res = await $http.delete(`/clients/shared-documents/drafts`, {
        data,
      });
      // toastSuccess(`Draft ${isPlural ? "s were" : "was"} deleted`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  sendDraftEmail: async (
    id: string,
    data: ISendDraftEmailRequest,
  ): Promise<{ message: string } | undefined> => {
    try {
      const res = await $http.post(
        `/clients/shared-documents/drafts/${id}/send`,
        data,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  undoDeleteDraft: async (
    data: IUndoDeleteDraftRequest,
  ): Promise<IUndoDeleteDraftResponse | undefined> => {
    try {
      const res = await $http.post(
        `/clients/shared-documents/drafts/undo-delete`,
        data,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(dashboardErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  downloadDraftDoc: async ({
    id,
  }: {
    id: string;
  }): Promise<IDownloadSentDocResponse | undefined> => {
    try {
      const res = await $http.get(
        `/clients/shared-documents/drafts/${id}/download`,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  getSentDocs: async ({
    orderBy,
    order,
    page,
    limit,
    filter,
  }: IGetSentDocsRequest): Promise<IGetSentDocsResponse | undefined> => {
    try {
      const query = queryString.stringify(
        { orderBy, order, page, limit, filter },
        { skipEmptyString: true },
      );
      const res = await $http.get(`/clients/shared-documents/sent?${query}`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  getSentDoc: async ({
    id,
  }: {
    id: string;
  }): Promise<IInboxDocDetailed | undefined> => {
    try {
      const res = await $http.get(`/clients/shared-documents/sent/${id}`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  downloadSentDoc: async ({
    id,
  }: {
    id: string;
  }): Promise<IDownloadSentDocResponse | undefined> => {
    try {
      const res = await $http.get(
        `/clients/shared-documents/sent/${id}/download`,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  moveSentDoc: async (
    id: string,
    parentFolderId: string,
  ): Promise<IEditedDraft | undefined> => {
    try {
      const res = await $http.post(
        `/clients/shared-documents/sent/${id}/move`,
        { parentFolderId },
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  renameSentDoc: async (
    id: string,
    name: string,
  ): Promise<{ message: string } | undefined> => {
    try {
      const res = await $http.put(
        `/clients/shared-documents/sent/${id}/rename`,
        { name },
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  cancelSentDoc: async (
    id: string,
  ): Promise<{ message: string } | undefined> => {
    try {
      const res = await $http.post(
        `/clients/shared-documents/sent/${id}/cancel`,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  deleteSentDoc: async (
    id: string,
  ): Promise<{ message: string } | undefined> => {
    try {
      const res = await $http.delete(`/clients/shared-documents/sent/${id}`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  undoDeleteSent: async (
    id: string,
  ): Promise<{ message: string } | undefined> => {
    try {
      const res = await $http.post(
        `/clients/shared-documents/sent/${id}/undo-delete`,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(dashboardErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  getInboxDocs: async ({
    orderBy,
    order,
    page,
    limit,
    filter,
  }: IGetInboxDocsRequest): Promise<IGetInboxDocsResponse | undefined> => {
    try {
      const query = queryString.stringify(
        { orderBy, order, page, limit, filter },
        { skipEmptyString: true },
      );
      const res = await $http.get(`/clients/shared-documents/inbox?${query}`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  getInboxDoc: async ({
    id,
  }: {
    id: string;
  }): Promise<IInboxDocDetailed | undefined> => {
    try {
      const res = await $http.get(`/clients/shared-documents/inbox/${id}`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  downloadInboxDoc: async ({
    id,
  }: {
    id: string;
  }): Promise<IDownloadInboxDocResponse | undefined> => {
    try {
      const res = await $http.get(
        `/clients/shared-documents/inbox/${id}/download`,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  signInboxDoc: async (
    id: string,
    data: ISignInboxDocRequest,
  ): Promise<{ isDocumentCompleted: boolean } | undefined> => {
    try {
      const res = await $http.post(
        `/clients/shared-documents/inbox/${id}/sign`,
        data,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  rejectInboxDoc: async (
    data: IRejectInboxDocRequest,
  ): Promise<{ message: string } | undefined> => {
    try {
      const res = await $http.post(
        `/clients/shared-documents/inbox/${data.id}/reject`,
        { reason: data.reason },
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        if (error.response?.status === 409) {
          return { message: "isError" };
        }
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  deleteInboxDoc: async (
    id: string,
  ): Promise<{ message: string } | undefined> => {
    try {
      const res = await $http.delete(`/clients/shared-documents/inbox/${id}`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  moveInboxDoc: async (
    id: string,
    parentFolderId: string,
  ): Promise<IEditedDraft | undefined> => {
    try {
      const res = await $http.post(
        `/clients/shared-documents/inbox/${id}/move`,
        { parentFolderId },
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  renameInboxDoc: async (
    id: string,
    name: string,
  ): Promise<{ message: string } | undefined> => {
    try {
      const res = await $http.put(
        `/clients/shared-documents/inbox/${id}/rename`,
        { name },
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  undoDeleteInbox: async (
    id: string,
  ): Promise<{ message: string } | undefined> => {
    try {
      const res = await $http.post(
        `/clients/shared-documents/inbox/${id}/undo-delete`,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(dashboardErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  undoMoveDocument: async (
    id: string,
  ): Promise<{ message: string } | undefined> => {
    try {
      const res = await $http.post(`/clients/shared-documents/${id}/undo-move`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(dashboardErrors(message));
      } else {
        console.error(e);
      }
    }
  },
  getGuestRecipientDoc: async ({
    id,
  }: {
    id: string;
  }): Promise<IInboxDocDetailed | undefined> => {
    try {
      const res = await $http.get(
        `/guests/shared-documents/recipient/link/${id}`,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  downloadGuestRecipientDoc: async ({
    id,
  }: {
    id: string;
  }): Promise<IDownloadInboxDocResponse | undefined> => {
    try {
      const res = await $http.get(
        `/guests/shared-documents/recipient/link/${id}/download`,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  signGuestRecipientDoc: async (
    id: string,
    data: ISignInboxDocRequest,
  ): Promise<{ isDocumentCompleted: boolean } | undefined> => {
    try {
      const res = await $http.post(
        `/guests/shared-documents/recipient/link/${id}/sign`,
        data,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  rejectGuestRecipientDoc: async (
    data: IRejectInboxDocRequest,
  ): Promise<{ message: string } | undefined> => {
    try {
      const res = await $http.post(
        `/guests/shared-documents/recipient/link/${data.id}/reject`,
        { reason: data.reason },
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  getGuestOwnerDoc: async ({
    id,
  }: {
    id: string;
  }): Promise<IInboxDocDetailed | undefined> => {
    try {
      const res = await $http.get(`/guests/shared-documents/owner/link/${id}`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  downloadGuestOwnerDoc: async ({
    id,
  }: {
    id: string;
  }): Promise<IDownloadInboxDocResponse | undefined> => {
    try {
      const res = await $http.get(
        `/guests/shared-documents/owner/link/${id}/download`,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  getActionsHistory: async ({
    id,
  }: {
    id: string;
  }): Promise<IGetActionsHistoryResponse | undefined> => {
    try {
      const res = await $http.get(`/clients/shared-documents/${id}/history`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  getGuestActionsHistory: async ({
    id,
  }: {
    id: string;
  }): Promise<IGetActionsHistoryResponse | undefined> => {
    try {
      const res = await $http.get(
        `/guests/shared-documents/link/${id}/history`,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  getCertificationInfo: async (
    id: string,
  ): Promise<IOpenedDocumentMetadata | undefined> => {
    try {
      const res = await $http.get(
        `/clients/shared-documents/${id}/certification-info`,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
  getGuestCertificationInfo: async (
    id: string,
  ): Promise<IOpenedDocumentMetadata | undefined> => {
    try {
      const res = await $http.get(
        `/guests/shared-documents/link/${id}/certification-info`,
      );
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message
          ? toastError(dashboardErrors(message))
          : toastError(
              Array.isArray(error.message) ? error.message[0] : error.message,
            );
      } else {
        console.error(e);
      }
    }
  },
};
