import {
  FC,
  useState,
  useEffect,
  ChangeEvent,
  useMemo,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, trashBin, folders } from "store";
import { setSelectedItems } from "store/trashBin";
import { getFolder } from "store/trashBin/thunks";

import { FilterType, NoTableData, BreadcrumbsDocs } from "components";
import { Checkbox, Icon } from "components/UI";
import { cs, getMenuItems, getMenuTitle } from "utils";
import { SelectedType, DataType, IParent } from "types";
import { useActions } from "hooks";

import { HeaderCell } from "../HeaderCell";
import { TrashBinTableModals } from "./Modals";
import { TrashBinTableRow } from "./Row";
import styles from "./styles.module.scss";

export const TrashBinTable: FC = () => {
  const dispatch = useAppDispatch();
  const {
    // search,
    allDeletedItems,
    selectedItems,
    restoredFolderId,
    currentFolder,
    softDeletedItems,
    isDeleteItemsModal,
    isRestoreItemsModal,
  } = useAppSelector(trashBin);
  const { itemsToMove } = useAppSelector(folders);
  // const [searchValue] = useDebounce(search, 1000);
  const { getAction } = useActions();
  const { t } = useTranslation("Table", {
    keyPrefix: "TrashBin",
  });
  const { t: tG } = useTranslation("General");
  const [tableData, setTableData] = useState<IParent[]>([]);
  const storageSorting = localStorage.getItem("sortingTrash");
  const parsedSorting = storageSorting && JSON.parse(storageSorting);
  const [sorting, setSorting] = useState({
    deletedAt: parsedSorting?.deletedAt === true || false,
  });

  const [selectedTypes, setSelectedTypes] = useState<SelectedType[]>([]);
  const openedFolderData = useMemo(
    () =>
      allDeletedItems.find((item) => item.folder?.id === currentFolder)?.items,
    [allDeletedItems, currentFolder],
  );
  const initialData = useMemo(() => openedFolderData || [], [openedFolderData]);

  const sortTable = useCallback(async () => {
    if (tableData.length > 0) {
      setTableData((prevState) =>
        prevState.slice().sort((a: DataType, b: DataType) => {
          const asc = sorting.deletedAt ? 1 : -1;
          const desc = sorting.deletedAt ? -1 : 1;

          return (a.deletedAt || 0) < (b.deletedAt || 0) ? asc : desc;
        }),
      );
    }
  }, [sorting, tableData.length]);

  const handleSort = useCallback(() => {
    localStorage.setItem(
      "sortingTrash",
      JSON.stringify({
        deletedAt: !sorting.deletedAt,
      }),
    );
    setSorting((prevState) => ({
      deletedAt: !prevState.deletedAt,
    }));
  }, [sorting]);

  useEffect(() => {
    restoredFolderId && dispatch(getFolder({ id: restoredFolderId }));
  }, [restoredFolderId, dispatch]);

  useEffect(() => {
    if (currentFolder) {
      dispatch(setSelectedItems([]));
      dispatch(getFolder({ id: currentFolder }));
    }
  }, [dispatch, currentFolder, itemsToMove]);

  // useEffect(() => {
  //   openedFolderData && setTableData(openedFolderData);
  // }, [initialData, openedFolderData]);

  useEffect(() => {
    sortTable();
  }, [sortTable]);

  // const searchItems = useCallback(async () => {
  //   if (searchValue) {
  //     try {
  //       const res = await TrashBin.searchFolder({ query: searchValue });
  //       if (res?.items) {
  //         setTableData(res.items);
  //       }
  //     } catch (error) {
  //       console.log("error:", error);
  //       if (isAxiosError(error)) {
  //         error?.message &&
  //           toastError(
  //             Array.isArray(error.message) ? error.message[0] : error.message,
  //           );
  //       }
  //     }
  //   } else {
  //     setTableData(openedFolderData || []);
  //     sortTable();
  //   }
  // }, [searchValue, openedFolderData, sortTable]);

  useEffect(() => {
    // searchItems();
    setTableData(openedFolderData || []);
    sortTable();
  }, [openedFolderData, sortTable]);

  const handleClearSelected = useCallback(() => {
    dispatch(setSelectedItems([]));
  }, [dispatch]);

  useEffect(() => {
    return handleClearSelected;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectAll = () => {
    dispatch(
      setSelectedItems(
        selectedItems.length < initialData?.length ? [...initialData] : [],
      ),
    );
  };

  const handleSelectItem = (item: DataType) => {
    dispatch(
      setSelectedItems(
        selectedItems.some((el) => el.id === item.id)
          ? [...selectedItems.filter((el) => el.id !== item.id)]
          : [...selectedItems, item],
      ),
    );
  };

  const handleFilter = ({
    target: { name },
  }: ChangeEvent<HTMLInputElement>) => {
    const newType = name as SelectedType;
    setSelectedTypes((prevState) =>
      prevState.includes(newType)
        ? [...prevState.filter((el) => el !== newType)]
        : [newType],
    );
  };

  const selectAllTypes = () => {
    setSelectedTypes([]);
  };

  const filteredData = useMemo(
    () =>
      tableData
        ?.filter(
          (el) => selectedTypes.length === 0 || selectedTypes.includes(el.type),
        )
        ?.filter(
          (el) =>
            !softDeletedItems.includes(el.id) ||
            isDeleteItemsModal ||
            isRestoreItemsModal,
        )
        .sort((a: DataType, b: DataType) => {
          return a.type === "FOLDER" && b.type !== "FOLDER" ? -1 : 1;
        })
        .sort((a, b) => a.daysLeftToHardDeletion - b.daysLeftToHardDeletion) ||
      [],
    [
      selectedTypes,
      tableData,
      softDeletedItems,
      isDeleteItemsModal,
      isRestoreItemsModal,
    ],
  );

  const allDates: number[] = [];

  return (
    <div className={styles.TrashBinTable}>
      <TrashBinTableModals />
      {/* {searchValue ? (
        <h2 className={styles.title}>
          {filteredData.length
            ? t("results", { count: filteredData.length })
            : t("noResults")}
        </h2>
      ) : ( */}
      <BreadcrumbsDocs
        className={styles.title}
        sliceSource="trashBin"
        rootFolderName={t("rootFolderName")}
      />
      {/* )} */}
      <header
        className={cs([styles.tr, selectedItems.length > 0 && styles.active])}
      >
        <Checkbox
          name={tG("all")}
          onChange={handleSelectAll}
          isChecked={
            selectedItems.length === initialData?.length &&
            selectedItems.length > 0
          }
        />
        {selectedItems.length > 0 ? (
          <ul className={styles.actions}>
            {getMenuItems({ selectedItems, isTrash: true })?.map(
              (el, index) => (
                <li
                  className={styles.action}
                  key={index}
                  onClick={() => getAction({ name: el, isTrash: true })()}
                >
                  <Icon name={el} size={18} />{" "}
                  {getMenuTitle({ name: el, isTrash: true })}
                </li>
              ),
            )}
          </ul>
        ) : (
          <>
            <HeaderCell name={tG("name")} />
            <div className={styles.th}>
              <FilterType
                onChange={handleFilter}
                selectAll={selectAllTypes}
                selectedTypes={selectedTypes}
              />
            </div>
            <HeaderCell name={t("attributes")} />
            <HeaderCell
              name={t("deletionDate")}
              onSort={handleSort}
              isSorted={sorting.deletedAt}
            />
          </>
        )}
      </header>
      {filteredData.map((item) => {
        let isLabel = true;
        allDates.push(item.daysLeftToHardDeletion);

        if (
          allDates.filter((el) => el === item.daysLeftToHardDeletion).length > 1
        ) {
          isLabel = false;
        }

        const isToday = item.daysLeftToHardDeletion === 0;
        const labelTextNextDays =
          item.daysLeftToHardDeletion === 1
            ? t("labelTextOneDay", {
                count: item.daysLeftToHardDeletion,
              })
            : t("labelTextFewDay", {
                count: item.daysLeftToHardDeletion,
              });

        return (
          <div key={item.id}>
            {isLabel && item.daysLeftToHardDeletion !== undefined && (
              <div
                className={cs([styles.deleteLabel, isToday && styles.active])}
              >
                {isToday ? t("labelTextToday") : labelTextNextDays}
              </div>
            )}
            <TrashBinTableRow
              onChange={() => handleSelectItem(item)}
              item={item}
            />
          </div>
        );
      })}

      <NoTableData
        // isSearch={!!searchValue}
        isNoFilteredData={filteredData?.length === 0}
        isNoData={
          initialData?.filter(
            (el) => !softDeletedItems.includes(el.id) || isDeleteItemsModal,
          )?.length === 0
        }
        selectedTypes={selectedTypes}
        isTrash
      />
    </div>
  );
};
