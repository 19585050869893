import { FC, useState, useEffect, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler";
import { useDebouncedCallback } from "use-debounce";
import { isAxiosError } from "axios";
import { useParams } from "react-router-dom";

import { Contacts, SharedDocuments } from "api";
import {
  useAppSelector,
  useAppDispatch,
  requestSigning,
  auth,
  contacts,
} from "store";
import { setRecipients, setUsedColors } from "store/requestSigning";
import { getContacts } from "store/contacts/thunks";

import { Input, Button, Spinner } from "components/UI";
import { palette, toastError, validateEmail, cs } from "utils";
import { IRecipient, IContact } from "types";
import { useEffectOnce } from "hooks";

import { ModalHeader } from "../ModalHeader";

import styles from "./styles.module.scss";

type AddRecipientModalProps = {
  onClose: () => void;
  onChange: () => void;
  recipient: IRecipient | null;
  recipientIndex?: number;
};

export const AddRecipientModal: FC<AddRecipientModalProps> = ({
  onClose,
  onChange,
  recipient,
  recipientIndex = 0,
}) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(auth);
  const { recipients, usedColors } = useAppSelector(requestSigning);
  const { allContacts } = useAppSelector(contacts);
  const { t } = useTranslation("Modals", { keyPrefix: "AddRecipient" });
  const { t: tG } = useTranslation("General");
  const { t: tR } = useTranslation("RequestSigning", {
    keyPrefix: "RecipientsList",
  });
  const { draftId } = useParams();
  const [contactsList, setContactsList] = useState<IContact[]>([]);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [id, setId] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [isLoading] = useState<boolean>(false);
  const [suggestionsFor, setSuggestionsFor] = useState<string>("");

  const handleSearchSuggestions = useDebouncedCallback(
    async (value: string) => {
      if (value) {
        try {
          const res = await Contacts.searchContact({ query: value });
          if (res?.items) {
            setContactsList(
              res.items.filter(
                (item) => !recipients.some((el) => el.email === item.email),
              ),
            );
          }
        } catch (error) {
          console.log("error:", error);
          if (isAxiosError(error)) {
            error?.message &&
              toastError(
                Array.isArray(error.message) ? error.message[0] : error.message,
              );
          }
        }
      }
    },
    500,
  );

  useEffectOnce(() => {
    if (allContacts.length === 0) {
      dispatch(getContacts());
    }
  });

  useEffect(() => {
    if (recipient) {
      setEmail(recipient.email);
      setFirstName(recipient.firstName);
      setLastName(recipient.lastName);
    }
  }, [recipient]);

  const checkEmailValidity = () => {
    const emailValidationErrors = email && validateEmail(email);

    if (emailValidationErrors.length > 0) {
      setEmailError(emailValidationErrors[0]);
    }
    return emailValidationErrors;
  };

  const handleChangeName = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.value.length > 100) return;
    target.name === "firstName"
      ? setFirstName(target.value)
      : setLastName(target.value);
    handleSearchSuggestions(target.value);
    setSuggestionsFor(`${target.name}${recipientIndex}`);
  };

  const handleChangeEmail = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.value?.length > 100) return;
    setEmailError("");
    if (recipients.some((el) => el.email === target.value)) {
      setEmailError(t("emailRecipientError"));
    } else if (target.value === user?.email) {
      setEmailError(t("emailOwnerError"));
    } else {
      setEmail(target.value);
      handleSearchSuggestions(target.value);
      setSuggestionsFor(`email${recipientIndex}`);
    }
  };

  const handleClearRecipienWidgets = () => {
    const savedInstantJSON = localStorage.getItem("instantJSON");
    const savedInstantJSONParsed = savedInstantJSON
      ? JSON.parse(savedInstantJSON)
      : null;
    const filteredAnnotations = savedInstantJSONParsed?.annotations?.filter(
      (item: any) => item.recipient !== recipients[recipientIndex]?.email,
    );
    const filteredFormFields = savedInstantJSONParsed?.formFields?.filter(
      (item: any) => item.recipient !== recipients[recipientIndex]?.email,
    );
    const filteredFormFieldValues =
      savedInstantJSONParsed?.formFieldValues?.filter(
        (item: any) => item.recipient !== recipients[recipientIndex]?.email,
      );
    const dataToSave = {
      ...savedInstantJSONParsed,
      annotations: filteredAnnotations,
      formFields: filteredFormFields,
      formFieldValues: filteredFormFieldValues,
    };
    const isAnyData =
      filteredAnnotations?.length > 0 ||
      filteredFormFields > 0 ||
      filteredFormFieldValues > 0;
    const dataStringified = isAnyData ? JSON.stringify(dataToSave) : "null";

    localStorage.setItem("instantJSON", dataStringified);
  };

  const handleSubmit = async () => {
    if (checkEmailValidity().length > 0) return;

    let res = [...recipients];
    if (recipient) {
      res[recipientIndex] = {
        ...res[recipientIndex],
        firstName,
        lastName,
        email,
      };
    } else {
      res.push({ id, firstName, lastName, email, error: "" });
      res = res.slice().sort((a, b) => a.firstName.localeCompare(b.firstName));
    }

    if (recipient && recipient?.email !== email) {
      handleClearRecipienWidgets();
    }

    dispatch(setRecipients(res));
    if (draftId) {
      await SharedDocuments.updateDraft(draftId, {
        recipients: res.map(({ firstName, lastName, email }) => ({
          firstName,
          lastName,
          email,
        })),
      });
    }

    onClose();
    onChange();
  };

  const handleClearName = (nameType: "firstName" | "lastName") => {
    nameType === "firstName" ? setFirstName("") : setLastName("");
  };

  const handleClearEmail = () => {
    setEmail("");
    setEmailError("");
  };

  const handleRemoveRecipient = async () => {
    const res = [...recipients];

    handleClearRecipienWidgets();
    onChange();

    res.splice(recipientIndex, 1);
    dispatch(setRecipients(res || []));
    dispatch(setUsedColors(usedColors.filter((el) => el.recipient !== email)));

    if (draftId) {
      await SharedDocuments.updateDraft(draftId, {
        recipients: res.map(({ firstName, lastName, email }) => ({
          firstName,
          lastName,
          email,
        })),
      });
    }
    onClose();
  };

  const handleCloseSuggestions = () => {
    setContactsList([]);
    setSuggestionsFor("");
  };

  const handleSelectContact = ({
    id,
    firstName,
    lastName,
    email,
  }: IContact) => {
    handleCloseSuggestions();
    setEmail(email);
    setEmailError("");
    setFirstName(firstName);
    setLastName(lastName);
    setId(id);
  };

  const renderSuggestions = () => {
    if (contactsList.length > 0) {
      return (
        <OutsideClickHandler onOutsideClick={handleCloseSuggestions}>
          <div className={styles.suggestions}>
            {contactsList.map((item) => (
              <div
                key={item.id}
                className={styles.contact}
                onClick={() => handleSelectContact(item)}
              >
                <div className={styles.name}>
                  {item.firstName} {item.lastName}
                </div>
                <div className={styles.email}>{item.email}</div>
              </div>
            ))}
          </div>
        </OutsideClickHandler>
      );
    }
  };

  const submitButtonTitle = recipient
    ? t("submitEditButtonTitle")
    : t("submitAddButtonTitle");

  return (
    <div className={styles.AddRecipientModal}>
      <ModalHeader
        onClose={onClose}
        title={recipient ? t("titleEdit") : t("titleAdd")}
      />
      <div className={cs([styles.inputWrap, styles.plural])}>
        <div className={styles.inputWrap}>
          <Input
            className={styles.nameInput}
            onChange={handleChangeName}
            value={firstName}
            name="firstName"
            isClearButton
            onClear={() => handleClearName("firstName")}
            placeholder={tR("inputFirstNamePlaceholder")}
            label={tR("inputFirstNameLabel")}
            autoComplete="off"
            isRequired
          />
          {suggestionsFor === `firstName${recipientIndex}` &&
            renderSuggestions()}
        </div>
        <div className={styles.inputWrap}>
          <Input
            className={styles.nameInput}
            onChange={handleChangeName}
            value={lastName}
            name="lastName"
            isClearButton
            onClear={() => handleClearName("lastName")}
            placeholder={tR("inputLastNamePlaceholder")}
            label={tR("inputLastNameLabel")}
            autoComplete="off"
            isRequired
          />
          {suggestionsFor === `lastName${recipientIndex}` &&
            renderSuggestions()}
        </div>
      </div>
      <div className={styles.inputWrap}>
        <Input
          onChange={handleChangeEmail}
          onBlur={checkEmailValidity}
          value={email}
          error={emailError}
          name="email"
          isClearButton
          onClear={handleClearEmail}
          placeholder={tG("emailPlaceholder")}
          label={tG("emailLabel")}
          autoComplete="off"
          isRequired
        />
        {suggestionsFor === `email${recipientIndex}` && renderSuggestions()}
      </div>
      <div className={styles.buttons}>
        <Button
          variant="primary"
          title={
            isLoading ? <Spinner color={palette.white} /> : submitButtonTitle
          }
          onClick={handleSubmit}
          isDisabled={!firstName || !lastName || !email || !!emailError}
        />
        {recipient && (
          <Button
            variant="secondary"
            title={t("cancelEditButtonTitle")}
            onClick={handleRemoveRecipient}
            className={styles.deleteButton}
          />
        )}
      </div>
    </div>
  );
};
