import { toCapitalizedWords } from "./conditionalNaming";

import i18next, { ns } from "../i18n";

export const authErrors = (errMessage: string | string[]) => {
  const { t } = i18next;
  switch (Array.isArray(errMessage) ? errMessage[0] : errMessage) {
    case "api.errors.auth.signup.verificationCodeExpired":
    case "api.errors.auth.forgotPassword.verificationCodeExpired":
    case "api.errors.settings.changeEmailVerificationCodeExpired":
      return t("Utils.Errors.AuthErrors.verificationCodeExpired", { ns });
    case "api.errors.auth.signup.wrongVerificationCode":
    case "api.errors.auth.forgotPassword.wrongVerificationCode":
    case "api.errors.settings.changeEmailWrongVerificationCode":
    case "api.errors.account.wrongEmailVerificationCode":
      return t("Utils.Errors.AuthErrors.wrongVerificationCode", { ns });
    case "api.errors.auth.signup.verificationCodeRecentlySent":
    case "api.errors.auth.forgotPassword.verificationCodeRecentlySent":
    case "api.errors.settings.changeEmailVerificationCodeRecentlySent":
    case "api.errors.account.emailVerificationCodeRecentlySent":
      return t("Utils.Errors.AuthErrors.verificationCodeRecentlySent", { ns });
    case "api.errors.auth.forgotPassword.verificationCodeIsUsed":
    case "api.errors.settings.changeEmailVerificationCodeIsUsed":
      return t("Utils.Errors.AuthErrors.verificationCodeIsUsed", { ns });
    case "api.errors.auth.signup.invalidPassword":
    case "api.errors.auth.resetPassword.invalidPassword":
    case "api.errors.settings.wrongPassword":
      return t("Utils.Errors.AuthErrors.invalidPassword", { ns });
    case "api.errors.auth.signup.invalidEmail":
    case "api.errors.auth.login.invalidEmail":
    case "api.errors.forgotPassword.invalidEmail":
    case "api.errors.forgotPassword.emailNotFound":
    case "api.errors.settings.wrongEmail":
    case "api.errors.settings.invalidEmail":
      return t("Utils.Errors.AuthErrors.invalidEmail", { ns });
    case "api.errors.login.incorrectEmailOrPassword":
      return t("Utils.Errors.AuthErrors.incorrectEmailOrPassword", { ns });
    case "api.errors.auth.signup.userExists":
      return t("Utils.Errors.AuthErrors.userExists", { ns });
    case "api.errors.auth.userWithEmailAlreadyExists":
      return t("Utils.Errors.AuthErrors.userWithEmailAlreadyExists", { ns });
    case "api.errors.auth.resetPassword.invalidVerification":
    case "api.errors.settings.wrongPasswordVerification":
    case "api.errors.auth.login.googleLoginInvalidToken":
    case "api.errors.auth.login.appleLoginInvalidToken":
      return t("Utils.Errors.AuthErrors.invalidVerification", { ns });
    case "api.errors.settings.passwordVerificationThrottling":
      return t("Utils.Errors.AuthErrors.passwordVerificationThrottling", {
        ns,
      });
    case "Verification code expired":
      return t("Utils.Errors.AuthErrors.expired", { ns });
    case "tryAgainLater":
      return t("Utils.Errors.AuthErrors.tryAgainLater", { ns });

    default:
      return errMessage.toString();
  }
};

export const dashboardErrors = (errMessage: string | string[]) => {
  const { t } = i18next;
  const errorString = Array.isArray(errMessage) ? errMessage[0] : errMessage;
  switch (errMessage) {
    case "api.errors.userFiles.totalFilesSizeExceedsLimitation":
      return t("Utils.Errors.DashboardErrors.totalFilesSizeExceedsLimitation", {
        ns,
      });
    case "api.errors.folders.userDocumentNotFound":
      return t("Utils.Errors.DashboardErrors.userDocumentNotFound", { ns });
    case "api.errors.folders.api.errors.files.documentUpdatingIsBlocked":
      return t("Utils.Errors.DashboardErrors.documentUpdatingIsBlocked", {
        ns,
      });
    case "api.errors.userFiles.fileSizeExceedsLimitation":
      return t("Utils.Errors.DashboardErrors.toastFileSizeError", { ns });
    case "noSignatureData":
      return t("Utils.Errors.DashboardErrors.noSignatureData", { ns });
    case "api.errors.sharedDocuments.conflictingInstantJson":
      return t("Utils.Errors.DashboardErrors.conflictingInstantJson", { ns });
    case "api.errors.sharedDocuments.sharedDocumentCanNotBeRejected":
      return t("Utils.Errors.DashboardErrors.sharedDocumentCanNotBeRejected", {
        ns,
      });
    case "api.errors.subscription.forbiddenToBuy":
      return t("Utils.Errors.DashboardErrors.forbiddenToBuy", { ns });
    case "api.errors.subscription.cancelForbidden":
      return t("Utils.Errors.DashboardErrors.cancelForbidden", { ns });
    case "api.errors.paymentMethod.notFound":
      return t("Utils.Errors.DashboardErrors.paymentMethodNotFound", { ns });
    case "api.errors.subscription.retryNeedsConfirmation":
      return t("Utils.Errors.DashboardErrors.retryNeedsConfirmation", { ns });
    case "api.errors.subscription.retryForbidden":
      return t("Utils.Errors.DashboardErrors.retryForbidden", { ns });
    case "api.errors.contacts.contactWithEmailExists":
      return t("Utils.Errors.DashboardErrors.contactWithEmailExists", { ns });

    default:
      return errorString ? toCapitalizedWords(errorString) : errorString;
  }
};

export const stripeErrors = (errMessage: string) => {
  const { t } = i18next;
  switch (errMessage) {
    case "insufficient_funds":
      return t("Subscription.StripeErrors.insufficient_funds", { ns });
    case "expired_card":
      return t("Subscription.StripeErrors.expired_card", { ns });
    case "card_velocity_exceeded":
      return t("Subscription.StripeErrors.card_velocity_exceeded", { ns });
    case "incorrect_number":
    case "invalid_number":
      return t("Subscription.StripeErrors.wrong_number", { ns });
    case "incorrect_cvc":
    case "invalid_cvc":
      return t("Subscription.StripeErrors.wrong_cvc", { ns });
    case "invalid_expiry_month":
    case "invalid_expiry_year":
      return t("Subscription.StripeErrors.wrong_date", { ns });
    case "authentication_required":
      return t("Subscription.StripeErrors.3d_secure_error", { ns });
    case "generic_decline":
      return t("Subscription.StripeErrors.generic_decline", { ns });
    case "payment_failed":
      return t("Subscription.StripeErrors.payment_failed", { ns });
    default:
      return t("Subscription.StripeErrors.defaultError", { ns });
  }
};
