import { palette } from "utils";

export const getButtonNode = (
  title: string,
  imgSrc: string,
  borderColor?: string,
) => {
  const childNodeImage = document.createElement("img");
  childNodeImage.src = imgSrc;
  const childNodeText = document.createTextNode(title);
  const parentNode = document.createElement("div");
  parentNode.style.padding = "0 1rem";
  parentNode.style.marginRight = "-2px";
  parentNode.style.height = "44px";
  parentNode.style.display = "flex";
  parentNode.style.alignItems = "center";
  parentNode.style.gap = "0.8rem";
  parentNode.style.borderLeft = `1px solid ${borderColor || "#F1F3F9"}`;
  parentNode.style.cursor = "pointer";
  parentNode.style.fontSize = "16px";
  parentNode.style.fontFamily = "Urbanist";
  parentNode.onmouseover = function hover() {
    parentNode.style.backgroundColor = "#f0f3f9";
  };
  parentNode.onmouseleave = function hover() {
    parentNode.style.backgroundColor = "initial";
  };
  parentNode.appendChild(childNodeImage);
  parentNode.appendChild(childNodeText);
  return parentNode;
};

export const signedStatusNode = (signed: number, total: number) => {
  const childNodeText = document.createTextNode(`Signed ${signed}/${total}`);
  const parentNodeWrap = document.createElement("div");
  const parentNode = document.createElement("div");
  const isAllSigned = total > 0 && total === signed;
  parentNodeWrap.style.height = "44px";
  parentNodeWrap.style.display = "flex";
  parentNodeWrap.style.alignItems = "center";
  parentNodeWrap.style.paddingRight = "10px";
  parentNodeWrap.style.paddingLeft = "10px";
  parentNodeWrap.style.borderLeft = "1px solid #F1F3F9";
  parentNode.style.padding = "0 1rem";
  parentNode.style.marginRight = "-2px";
  parentNode.style.marginTop = "auto";
  parentNode.style.marginBottom = "auto";
  parentNode.style.height = "26px";
  parentNode.style.display = "flex";
  parentNode.style.alignItems = "center";
  parentNode.style.borderRadius = "25px";
  parentNode.style.fontFamily = "Urbanist";
  parentNode.style.fontSize = "16px";
  parentNode.style.color = isAllSigned ? palette.green : palette.blue;
  parentNode.style.backgroundColor = isAllSigned
    ? palette.green20
    : palette.blue30;
  parentNodeWrap.appendChild(parentNode);
  parentNode.appendChild(childNodeText);
  return parentNodeWrap;
};

export const getEmptyNode = (width: number, borderLeft?: string) => {
  const parentNode = document.createElement("div");
  parentNode.style.width = `${width}rem`;
  parentNode.style.height = "44px";
  borderLeft && (parentNode.style.borderLeft = borderLeft);
  return parentNode;
};

export const getPointerTitle = (widgetName?: string) => {
  if (widgetName?.startsWith("SIGNATURE_WIDGET")) {
    return "Signature";
  }
  if (widgetName?.startsWith("SIGNATURE_INITIALS")) {
    return "Initials";
  }
  if (widgetName?.startsWith("DATE_WIDGET")) {
    return "Date";
  }
  if (widgetName?.startsWith("CHECKBOX_WIDGET")) {
    return "Skip";
  }
  if (widgetName?.startsWith("TEXT_WIDGET")) {
    return "Skip";
  }
  return "Start";
};
