import { FC, useState, ChangeEvent, useRef } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import Transition, {
  TransitionStatus,
} from "react-transition-group/Transition";
import { useTranslation } from "react-i18next";

import { Icon, RadioButton } from "components/UI";
import { cs, getFolderItems, opacityTransitionConfig } from "utils";
import { SelectedType } from "types";

import styles from "./styles.module.scss";

type FilterTypeProps = {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  selectAll: () => void;
  selectedTypes: SelectedType[];
};

export const FilterType: FC<FilterTypeProps> = ({
  onChange = () => {},
  selectAll = () => {},
  selectedTypes,
}) => {
  const [isFilterMenu, setIsFilterMenu] = useState<boolean>(false);
  const nodeRef = useRef(null);
  const { t } = useTranslation("Dashboard");

  const label =
    selectedTypes.length === 3 || selectedTypes.length === 0
      ? "All"
      : getFolderItems()
          .filter((el) => selectedTypes.includes(el.value))
          .map((el) => el.title)
          .join(", ");

  const toggleMenu = () => {
    setIsFilterMenu((prevState) => !prevState);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setIsFilterMenu(false)}>
      <div className={styles.FilterType}>
        <div className={styles.label} onClick={toggleMenu}>
          {t("filterTypeTitle")}: {label}
        </div>
        <Transition
          in={isFilterMenu}
          appear
          mountOnEnter
          unmountOnExit
          timeout={opacityTransitionConfig().timeout}
          nodeRef={nodeRef}
        >
          {(state: TransitionStatus) => (
            <ul
              ref={nodeRef}
              className={styles.menu}
              style={{
                ...opacityTransitionConfig().defaultStyles,
                ...opacityTransitionConfig().transitionStyles[state],
              }}
            >
              <RadioButton
                onChange={selectAll}
                title="All"
                name="all"
                isChecked={selectedTypes.length === 0}
                size={16}
              />
              {getFolderItems().map((el, index) => (
                <li key={index}>
                  <RadioButton
                    onChange={onChange}
                    title={el.title}
                    name={el.value}
                    isChecked={selectedTypes.includes(el.value)}
                    size={16}
                  />
                </li>
              ))}
            </ul>
          )}
        </Transition>
        <Icon
          name="chevron-left-empty"
          className={cs([styles.arrow, isFilterMenu && styles.down])}
          action={toggleMenu}
          size={16}
        />
      </div>
    </OutsideClickHandler>
  );
};
