import { FC, useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, settings } from "store";
import { Settings } from "api";
import { setUser } from "store/settings";

import { Button, Input, Spinner } from "components/UI";

import { toastSuccess, palette } from "utils";

import { ModalHeader } from "../ModalHeader";
import styles from "./styles.module.scss";

type EditNameModalProps = {
  onClose: () => void;
};

export const EditNameModal: FC<EditNameModalProps> = ({ onClose }) => {
  const { t } = useTranslation("Modals", { keyPrefix: "EditName" });
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(settings);
  const [firstName, setFirstName] = useState<string>(user?.firstName || "");
  const [lastName, setLastName] = useState<string>(user?.lastName || "");
  const [errors, setErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async () => {
    if (isLoading) return;
    if (firstName && lastName) {
      setIsLoading(true);
      const res = await Settings.changeName({ firstName, lastName });

      if (res?.id) {
        dispatch(setUser(res));
        toastSuccess(t("toastSuccess"));
      }
      setIsLoading(false);
      onClose();
    }
  };

  const handleChangeName = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.value.length > 100) return;
    if (target.value.length === 0) {
      setErrors([...errors, target.name]);
    } else {
      setErrors(errors.filter((el) => el !== target.name));
    }
    target.name === "firstName"
      ? setFirstName(target.value)
      : setLastName(target.value);
  };

  return (
    <div className={styles.EditNameModal}>
      <ModalHeader onClose={handleClose} title={t("title")} info={t("info")} />
      <Input
        className={styles.nameInput}
        onChange={handleChangeName}
        value={firstName}
        name="firstName"
        // onClear={() => setFirstName("")}
        placeholder={t("firstNamePlaceholder")}
        label={t("firstNameLabel")}
        autoComplete="off"
        error={errors.includes("firstName") && t("firstNameError")}
        // isClearButton
        isRequired
      />
      <Input
        className={styles.nameInput}
        onChange={handleChangeName}
        value={lastName}
        name="lastName"
        // onClear={() => setLastName("")}
        placeholder={t("lastNamePlaceholder")}
        label={t("lastNameLabel")}
        autoComplete="off"
        error={errors.includes("lastName") && t("lastNameError")}
        // isClearButton
        isRequired
      />

      <div className={styles.buttons}>
        <Button
          variant="primary"
          title={
            isLoading ? (
              <Spinner color={palette.white} />
            ) : (
              t("submitButtonTitle")
            )
          }
          onClick={handleSubmit}
          isDisabled={!firstName || !lastName}
        />
        <Button
          variant="secondary"
          title={t("cancelButtonTitle")}
          onClick={handleClose}
        />
      </div>
    </div>
  );
};
