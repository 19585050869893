import { FC, useState, ChangeEvent, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, auth, settings } from "store";
import {
  setAuthenticated,
  setUser,
  setForgotEmail,
  setEmail,
  setEditablePassword,
  setEditableEmail,
} from "store/auth";
import { Auth, Settings, setSession } from "api";

import { Input, Button, Icon } from "components/UI";
import { PATHES } from "constants/pathes";
import { validatePassword, cs, getPasswordIcon, sleep } from "utils";

import styles from "./styles.module.scss";

export const CreatePassword: FC = () => {
  const {
    editablePassword,
    verificationId,
    email,
    forgotEmail,
    firstName,
    lastName,
  } = useAppSelector(auth);
  const { passwordVerificationId } = useAppSelector(settings);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Auth", { keyPrefix: "CreatePassword" });
  const [password, setPassword] = useState<string>("");
  const [errors, setErrors] = useState<string[]>([]);
  const [isPasswordVisible, setIsPassVisible] = useState<boolean>(false);
  const requirements = [
    t("minEightChars"),
    t("oneUpLetter"),
    t("oneLowLetter"),
    t("oneNumber"),
  ];

  const handleChangePassword = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    if (value.length > 40) {
      return;
    }

    setErrors([]);
    const validateErrors = validatePassword(value);
    setErrors(validateErrors);
    setPassword(value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password && errors.length === 0) {
      let res;
      if (editablePassword && passwordVerificationId) {
        res = await Settings.changePassword({
          password,
          verificationId: passwordVerificationId,
        });
      } else if (forgotEmail) {
        res = await Auth.resetPassword({
          password,
          verificationId,
        });
      } else {
        res = await Auth.signUp({
          firstName,
          lastName,
          password,
          email,
          verificationId,
        });
      }

      if (editablePassword && res?.message === "ok") {
        dispatch(setAuthenticated(true));
        dispatch(setEditablePassword("updated"));
        await sleep(100);
        navigate(PATHES.SETTINGS);
      }

      if (res?.accessToken) {
        const { accessToken, refreshToken, user } = res;
        dispatch(setUser(user));
        setSession({
          accessToken,
          refreshToken,
          user,
        });
        if (!user?.isEmailSet) {
          navigate(PATHES.FORGOT_PASSWORD);
          dispatch(setEditableEmail("private"));
          return;
        }
        dispatch(setAuthenticated(true));
        dispatch(setForgotEmail(""));
        dispatch(setEmail(""));
        navigate(PATHES.HOME);
      }
    }
  };

  const togglePasswordVisibility = () => {
    setIsPassVisible((prevState) => !prevState);
  };

  return (
    <form className={styles.CreatePassword} onSubmit={handleSubmit}>
      <h1 className={styles.title}>
        {t("title", { new: forgotEmail ? t("new") : "" })}
      </h1>
      <Input
        type={isPasswordVisible ? "text" : "password"}
        onChange={handleChangePassword}
        value={password}
        name="password"
        iconEnd={getPasswordIcon(password, isPasswordVisible)}
        iconEndType="stroke"
        iconEndAction={togglePasswordVisibility}
        placeholder={t("inputPlaceholder")}
      />
      <ul className={styles.requirements}>
        {requirements.map((item, index) => (
          <li
            key={index}
            className={cs([
              styles.requirement,
              password && !errors.includes(item) && styles.success,
            ])}
          >
            <Icon name="done-success" size={12} /> {item}
          </li>
        ))}
      </ul>
      <Button
        variant="primary"
        title={t("buttonTitle")}
        onClick={handleSubmit}
        isDisabled={!password || errors.length !== 0}
        className={styles.submitButton}
      />
    </form>
  );
};
