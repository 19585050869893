import { FC, useCallback } from "react";
import dayjs from "dayjs";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";

import {
  useAppSelector,
  useAppDispatch,
  requestSigning,
  dashboard,
} from "store";
import {
  setCurrentFolder,
  setSelectedItems,
  setSearch,
} from "store/requestSigning";

import { DocImage, DocBreadcrumbs } from "components";
import { Checkbox, Icon } from "components/UI";

import { cs, getFolderItemName } from "utils";
import { DataType } from "types";
import { useTableRow } from "hooks";

import styles from "./styles.module.scss";

interface ISelectFromMyDocsTableRowProps {
  item: DataType;
  onOpenFolder: (folder: DataType) => Promise<void>;
  isOpened: boolean;
  isSearch?: boolean;
  indent: number;
}

export const SelectFromMyDocsTableRow: FC<ISelectFromMyDocsTableRowProps> = ({
  item,
  onOpenFolder,
  isOpened,
  isSearch,
  indent,
}) => {
  const { type, childrenCount, modifiedAt, previewUrl, id, name, parents } =
    item;
  const { handleOver, handleLeave, handleClearClicked } = useTableRow();
  const { selectedItems } = useAppSelector(requestSigning);
  const { dateFormat, timeFormat } = useAppSelector(dashboard);

  const dispatch = useAppDispatch();
  const { t } = useTranslation("Table", {
    keyPrefix: "SelectFromMyDocs.Row",
  });
  // const [clicks, setClicks] = useState<number>(0);

  const handleOpenFolder = useCallback(() => {
    if (isSearch) {
      dispatch(setCurrentFolder(id));
      dispatch(setSearch(""));
    } else {
      onOpenFolder(item);
    }
  }, [onOpenFolder, item, dispatch, isSearch, id]);

  const handleSelectFile = () => {
    if (type !== "FOLDER") {
      dispatch(
        setSelectedItems(
          selectedItems.some((el) => el.id === item.id)
            ? [...selectedItems.filter((el) => el.id !== item.id)]
            : [...selectedItems, item],
        ),
      );
    } else {
      handleOpenFolder();
    }
  };

  const isSelected = selectedItems.some((el) => el.id === id);

  return (
    <>
      <OutsideClickHandler onOutsideClick={handleClearClicked}>
        <div
          onMouseEnter={handleOver}
          onMouseLeave={handleLeave}
          className={cs([
            styles.SelectFromMyDocsTableRow,
            isSelected && styles.selected,
          ])}
          style={{ paddingLeft: `${2 + (indent || 0) * 2}rem` }}
        >
          <div className={styles.td}>
            {!isSearch && type === "FOLDER" && Number(childrenCount) > 0 && (
              <Icon
                className={cs([styles.chevron, isOpened && styles.opened])}
                name="chevron-down-empty"
                action={handleOpenFolder}
                size={20}
              />
            )}
            {type !== "FOLDER" && (
              <Checkbox
                name={id}
                onChange={handleSelectFile}
                isChecked={isSelected}
              />
            )}
          </div>
          <div className={cs([styles.td, styles.black])}>
            <div className={styles.name} onClick={handleSelectFile}>
              <DocImage type={type} previewUrl={previewUrl} />
              <div className={styles.nameWithDir}>
                {parents && <DocBreadcrumbs parents={parents} />}
                <div className={styles.textName}>{name}</div>
              </div>
            </div>
          </div>
          <div className={styles.td}>{getFolderItemName()[type]}</div>
          <div className={styles.td}>
            {`${childrenCount || 0} ${
              type === "FOLDER"
                ? `${childrenCount === 1 ? t("item") : t("items")}`
                : `${childrenCount === 1 ? t("page") : t("pages")}`
            } `}
          </div>
          <div className={styles.td}>
            {dayjs(modifiedAt).format(`${dateFormat}, ${timeFormat}`)}
          </div>
        </div>
      </OutsideClickHandler>
    </>
  );
};
