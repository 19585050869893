import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, trashBin } from "store";
import {
  setIsDeleteItemsModal,
  setIsRestoreItemsModal,
  setSoftDeletedItems,
} from "store/trashBin";
import {
  getFolder,
  undoDocument as undoTrashDocument,
} from "store/trashBin/thunks";
import { undoDocument } from "store/folders/thunks";
import { getQuota } from "store/dashboard/thunks";

import { AreYouSureModal } from "components";
import { Modal } from "components/UI";
import { toastUndo } from "utils";
import { useActions } from "hooks";

// import styles from "./styles.module.scss";

export const TrashBinTableModals: FC = () => {
  const dispatch = useAppDispatch();
  const {
    allDeletedItems,
    selectedItems,
    currentFolder,
    isDeleteItemsModal,
    isRestoreItemsModal,
    itemsToDelete,
    itemsToRestore,
  } = useAppSelector(trashBin);
  const { t } = useTranslation("Table", {
    keyPrefix: "TrashBin.Modals",
  });
  const { handleHardDeleteSuccess, handleRestoreSuccess } = useActions();
  const openedFolderData = useMemo(
    () =>
      allDeletedItems.find((item) => item.folder?.id === currentFolder)?.items,
    [allDeletedItems, currentFolder],
  );

  // const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false);
  // const [isRestoreModal, setIsRestoreModal] = useState<boolean>(false);

  const handleCloseAreYouSureDelete = () => {
    dispatch(setIsDeleteItemsModal(false));
    dispatch(setSoftDeletedItems([]));
  };

  const handleConfirmAreYouSureDelete = async () => {
    // setIsDeleteModal(true);
    const id =
      itemsToDelete.length > 0
        ? itemsToDelete[itemsToDelete.length - 1]
        : selectedItems[0]?.id;

    const isFolder =
      openedFolderData?.find((item) => item.id === id)?.type === "FOLDER";
    const isPlural = itemsToDelete.length === 0 && selectedItems.length > 1;

    let text = t("confirmDeleteFileToast");
    let textUndo = t("confirmDeleteFileToastUndo");

    if (isPlural) {
      text = t("confirmDeletePluralToast");
      textUndo = t("confirmDeletePluralToastUndo");
    } else if (isFolder) {
      text = t("confirmDeleteFolderToast");
      textUndo = t("confirmDeleteFolderToastUndo");
    }

    const isDeleted = await handleHardDeleteSuccess();

    if (isDeleted) {
      toastUndo({
        id,
        text,
        textUndo,
        onUndo: async () => {
          await dispatch(undoTrashDocument({ folderIds: itemsToDelete }));
          dispatch(getFolder({ id: currentFolder }));
          dispatch(getQuota());
        },
      });
    }

    dispatch(setIsDeleteItemsModal(false));
  };

  // const handleCancelDeletion = () => {
  //   setIsDeleteModal(false);
  //   toastInfo("Deleting canceled");
  // };

  // const handleSubmitDeletion = () => {
  //   if (isDeleteModal) {
  //     handleHardDeleteSuccess();
  //   }
  //   setIsDeleteModal(false);
  // };

  const handleCloseAreYouSureRestore = () => {
    dispatch(setIsRestoreItemsModal(false));
    dispatch(setSoftDeletedItems([]));
  };

  const handleConfirmAreYouSureRestore = async () => {
    const id =
      itemsToRestore.length > 0
        ? itemsToRestore[itemsToRestore.length - 1]
        : selectedItems[0]?.id;

    const isFolder =
      openedFolderData?.find((item) => item.id === id)?.type === "FOLDER";
    const isPlural = itemsToRestore.length === 0 && selectedItems.length > 1;

    let text = t("confirmRestoreFileToast");
    let textUndo = t("confirmRestoreFileToastUndo");

    if (isPlural) {
      text = t("confirmRestorePluralToast");
      textUndo = t("confirmRestorePluralToastUndo");
    } else if (isFolder) {
      text = t("confirmRestoreFolderToast");
      textUndo = t("confirmRestoreFolderToastUndo");
    }

    const isRestored = await handleRestoreSuccess();

    if (isRestored) {
      toastUndo({
        id,
        text,
        textUndo,
        onUndo: async () => {
          await dispatch(undoDocument({ folderIds: itemsToRestore }));
          dispatch(getFolder({ id: currentFolder }));
        },
      });
    }
  };

  // const handleCancelRestoring = () => {
  //   setIsRestoreModal(false);
  //   toastInfo("Restoring canceled");
  // };

  // const handleSubmitRestoring = () => {
  //   if (isRestoreModal) {
  //     handleRestoreSuccess();
  //   }
  //   setIsRestoreModal(false);
  // };

  return (
    <>
      {/* <Modal isShowed={isDeleteModal} className={styles.MoveModal} noDarkness>
        <MovingProgressModal
          onCancel={handleCancelDeletion}
          onSuccess={handleSubmitDeletion}
          title="Deleting, please wait..."
          iconName="trash"
        />
      </Modal> */}
      <Modal
        isShowed={isDeleteItemsModal}
        onClose={handleCloseAreYouSureDelete}
      >
        <AreYouSureModal
          onClose={handleCloseAreYouSureDelete}
          onSubmit={handleConfirmAreYouSureDelete}
          info={t("areYouSureDeleteInfo")}
          confirmButtonTitle={t("areYouSureDeleteIConfirmButtonTitle")}
          noCloseOnSubmit
        />
      </Modal>
      {/* <Modal isShowed={isRestoreModal} className={styles.MoveModal} noDarkness>
        <MovingProgressModal
          onCancel={handleCancelRestoring}
          onSuccess={handleSubmitRestoring}
          title="Restoring, please wait..."
          iconName="trash"
        />
      </Modal> */}
      <Modal
        isShowed={isRestoreItemsModal}
        onClose={handleCloseAreYouSureRestore}
      >
        <AreYouSureModal
          onClose={handleCloseAreYouSureRestore}
          onSubmit={handleConfirmAreYouSureRestore}
          info={t("areYouSureRestoreInfo")}
          confirmButtonTitle={t("areYouSureRestoreIConfirmButtonTitle")}
          isSubmitPositive
        />
      </Modal>
    </>
  );
};
