import { useState, useEffect, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, auth, settings } from "store";
import {
  setAuthenticated,
  setEditablePassword,
  setEditableEmail,
} from "store/auth";
import { getUser } from "store/settings/thunks";
import { setTempPass } from "store/settings";

import { PATHES } from "constants/pathes";
import { Input, Icon, Modal } from "components/UI";
import {
  ConfirmPasswordModal,
  EmailChangedModal,
  EditNameModal,
} from "components";

import { cs, toastSuccess, sleep } from "utils";
import { useEffectOnce } from "hooks";

import styles from "./styles.module.scss";

export const EmailPasswordName = () => {
  const { editablePassword, editableEmail } = useAppSelector(auth);
  const { user } = useAppSelector(settings);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("Settings", {
    keyPrefix: "EmailPasswordName",
  });
  const [isConfirmPasswordModal, setIsConfirmPasswordModal] =
    useState<boolean>(false);
  const [isEditNameModal, setIsEditNameModal] = useState<boolean>(false);
  const [editable, setEditable] = useState<string>("");
  const [firstName, setFirstName] = useState<string>(user?.firstName || "");
  const [lastName, setLastName] = useState<string>(user?.lastName || "");

  useEffect(() => {
    if (editablePassword === "updated") {
      toastSuccess(t("passwordUpdatedToastSuccess"));
      dispatch(setEditablePassword(""));
      dispatch(setTempPass(""));
    } else if (editablePassword === "to_edit" && !editable) {
      dispatch(setTempPass(""));
      setEditable("password");
      setIsConfirmPasswordModal(true);
    }
  }, [dispatch, editablePassword, t, editable]);

  useEffect(() => {
    if (editableEmail === "old" && !editable) {
      setEditable("email");
      setIsConfirmPasswordModal(true);
    }
  }, [editableEmail, editable]);

  useEffectOnce(() => {
    dispatch(getUser());
  });

  useEffect(() => {
    if (user?.firstName) {
      setFirstName(user?.firstName);
    }
  }, [user?.firstName]);

  useEffect(() => {
    if (user?.lastName) {
      setLastName(user?.lastName);
    }
  }, [user?.lastName]);

  const openEditPasswordModal = () => {
    setIsConfirmPasswordModal(true);
    setEditable("password");
  };

  const openEditEmailModal = () => {
    setIsConfirmPasswordModal(true);
    setEditable("email");
  };

  const openEditNameModal = () => {
    setIsEditNameModal(true);
  };

  const closeEditNameModal = () => {
    setIsEditNameModal(false);
  };

  const handleClosePasswordModal = () => {
    dispatch(setEditableEmail(""));
    dispatch(setEditablePassword(""));
    dispatch(setTempPass(""));
    setIsConfirmPasswordModal(false);
    setEditable("");
  };

  const preventChange = (e: ChangeEvent) => {
    e.preventDefault();
  };

  const changePassword = async () => {
    dispatch(setAuthenticated(false));
    dispatch(setEditablePassword("to_edit"));
    dispatch(setTempPass(""));
    setIsConfirmPasswordModal(false);
    await sleep(100);
    navigate(PATHES.CREATE_PASSWORD);
  };

  const changeEmail = async () => {
    dispatch(setAuthenticated(false));
    dispatch(setEditableEmail("old"));
    dispatch(setTempPass(""));
    setIsConfirmPasswordModal(false);
    await sleep(100);
    navigate(PATHES.FORGOT_PASSWORD);
  };

  // const handleUpdateFirstName = () => {
  //   if (firstName) {
  //     dispatch(updatedUserInfo({ firstName }));
  //   } else if (user?.firstName) {
  //     setFirstName(user?.firstName);
  //   }
  // };

  // const handleUpdateLastName = () => {
  //   if (lastName) {
  //     dispatch(updatedUserInfo({ lastName }));
  //   } else if (user?.lastName) {
  //     setLastName(user?.lastName);
  //   }
  // };

  return (
    <>
      <Modal
        isShowed={isConfirmPasswordModal}
        onClose={handleClosePasswordModal}
      >
        <ConfirmPasswordModal
          onClose={handleClosePasswordModal}
          onSubmit={editable === "password" ? changePassword : changeEmail}
        />
      </Modal>
      <Modal isShowed={editableEmail === "updated"}>
        <EmailChangedModal />
      </Modal>
      <Modal isShowed={isEditNameModal}>
        <EditNameModal onClose={closeEditNameModal} />
      </Modal>

      <div className={cs([styles.EmailPasswordName, styles.item])}>
        <div className={styles.inputWrap}>
          <Input
            className={styles.input}
            type="email"
            placeholder={user?.email || "davidsmith@aposti.com"}
            name="email"
            label={t("inputEmailLabel")}
            onChange={preventChange}
          />
          <Icon
            name="edit"
            className={styles.edit}
            action={openEditEmailModal}
          />
        </div>
        <div className={styles.inputWrap}>
          <Input
            className={styles.input}
            type="text"
            placeholder="*************"
            name="password"
            label={t("inputPasswordLabel")}
            onChange={preventChange}
          />
          <Icon
            name="edit"
            className={styles.edit}
            action={openEditPasswordModal}
          />
        </div>
        <div className={styles.inputWrap}>
          <Input
            type="text"
            // placeholder={t("inputFirstNamePlaceholder")}
            name="fisrtName"
            label={t("inputFirstNameLabel")}
            onChange={preventChange}
            value={firstName}
            autoComplete="off"
          />
          <Icon
            name="edit"
            className={styles.edit}
            action={openEditNameModal}
          />
        </div>
        <div className={styles.inputWrap}>
          <Input
            type="text"
            // placeholder={t("inputLastNamePlaceholder")}
            name="lastName"
            label={t("inputLastNameLabel")}
            onChange={preventChange}
            value={lastName}
            autoComplete="off"
          />
          <Icon
            name="edit"
            className={styles.edit}
            action={openEditNameModal}
          />
        </div>
      </div>
    </>
  );
};
