import { useEffect, useState, ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";

import { PATHES } from "constants/pathes";
import { MAX_INSTANT_JSON_SIZE } from "constants/index";
import {
  useAppDispatch,
  useAppSelector,
  dashboard,
  requestSigning,
} from "store";
import { SharedDocuments } from "api";
import {
  setActiveRecipient,
  setIsDeleteDraftModal,
  setRecipients,
  setIsRequestReady,
  setUsedColors,
} from "store/requestSigning";
import { getDraft } from "store/signings/thunks";

import { Input, Button, Modal, Icon } from "components/UI";
import { DocIDCopy, AddRecipientModal } from "components";

import { cs, pspdfkitColorsList, sleep, toastError, toastSuccess } from "utils";
import { IRecipient } from "types";
import { useInstance } from "hooks";

import styles from "./styles.module.scss";

type RequestWidgetProps = {
  onChange: () => void;
};

type RecipientWithColor = {
  recipient: string;
  color: string;
};

export const RequestWidget: FC<RequestWidgetProps> = ({ onChange }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("RequestSigning", {
    keyPrefix: "RequestWidget",
  });
  const navigate = useNavigate();
  const { dateFormat, timeFormat } = useAppSelector(dashboard);
  const { editedDraft, activeRecipient, fileName, recipients, usedColors } =
    useAppSelector(requestSigning);
  const [isEditName, setIsEditName] = useState<boolean>(false);
  const [isRecipientModal, setIsRecipientModal] = useState<boolean>(false);
  const [tempName, setTempName] = useState<string>("");
  const [editedRecipient, setEditedRecipient] = useState<IRecipient | null>(
    null,
  );
  const [editedRecipientIndex, setEditedRecipientIndex] = useState<number>(0);
  const [isMinimized, setIsMinimized] = useState<boolean>(false);
  const [isResizer, setIsResizer] = useState<boolean>(false);
  const { draftId } = useParams();
  const { getSavedJSON } = useInstance();

  useEffect(() => {
    if (
      usedColors.length < recipients.length ||
      usedColors.some(
        (el) => !recipients.map((item) => item.email).includes(el.recipient),
      )
    ) {
      const savedJSON = getSavedJSON();
      const filteredColors = pspdfkitColorsList.filter(
        (el) =>
          !savedJSON?.annotations?.some(
            (item: any) => item.backgroundColor === el.standart,
          ),
      );
      // const recipientAnnotation = savedJSON?.annotations
      //   ?.filter((el: any) => el.backgroundColor)
      //   ?.find((el: any) => el.recipient === activeRecipient?.email);
      const annotationColors = savedJSON?.annotations?.map((el: any) => ({
        recipient: el.recipient,
        color: el.backgroundColor,
      }));
      // const activeRecipientColor = recipientAnnotation?.backgroundColor;
      const doubleFiltered = filteredColors.filter((el) =>
        usedColors.some((used) => used.color !== el?.standart),
      );

      const updatedColors: RecipientWithColor[] = Array.from(
        new Map(
          recipients.map((item, index) => {
            const isExist = annotationColors?.some(
              (el: any) =>
                // el.color === pspdfkitColorsList[index]?.standart &&
                item.email === el.recipient,
            );
            const additionalColor = isExist
              ? doubleFiltered[0]?.standart
              : pspdfkitColorsList[index]?.standart;
            const annotationColor =
              (annotationColors?.length > 0 &&
                annotationColors.find((el: any) => el.recipient === item.email)
                  ?.color) ||
              doubleFiltered[index]?.standart ||
              pspdfkitColorsList[index]?.standart;
            return [
              item.email,
              {
                recipient: item.email,
                // color: pspdfkitColorsList[index]?.standart,
                // color:
                //   item.email === recipientAnnotation?.recipient
                //     ? activeRecipientColor
                //     : additionalColor,
                color:
                  annotationColors?.length > 0
                    ? annotationColor
                    : additionalColor,
              },
            ];
          }),
        ).values(),
      );
      dispatch(setUsedColors(updatedColors));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getSavedJSON, activeRecipient, usedColors, recipients]);

  useEffect(() => {
    setTempName(editedDraft?.name || "");
  }, [editedDraft]);

  useEffect(() => {
    if (editedDraft) {
      dispatch(setRecipients(editedDraft.recipients));
    }
  }, [dispatch, editedDraft]);

  const hadleClickName = () => {
    if (!isMinimized) {
      setIsEditName(true);
    }
  };

  const handleLeaveName = () => {
    setIsEditName(false);
    if (!fileName) {
      setTempName((prevState) => prevState || editedDraft?.name || "");
    }
  };

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 80) return;
    setTempName(e.target.value);
  };

  const handleGoNext = async () => {
    const savedInstant = localStorage.getItem("instantJSON");
    const parsedInstant = savedInstant && JSON.parse(savedInstant);
    const size = savedInstant
      ? new TextEncoder()?.encode(savedInstant)?.length
      : 0;
    const recipientsEmails = recipients.map((item: IRecipient) => item.email);
    const allFieldsRecipients = parsedInstant?.formFields?.map(
      (item: any) => item.recipient,
    );
    const allSignatureFieldsRecipients = parsedInstant?.formFields
      ?.filter((el: any) => el.name.startsWith("SIGNATURE_WIDGET"))
      .map((item: any) => item.recipient);
    if (
      [...new Set(allSignatureFieldsRecipients)].length <
      recipientsEmails.length
    ) {
      toastError(t("noSignatureField"));
      return;
    }
    if (
      [...new Set(allFieldsRecipients)].length < recipientsEmails.length ||
      parsedInstant?.formFields?.filter(
        (el: any) => !el.name.startsWith("TEXT_WIDGET"),
      ).length === 0
    ) {
      toastError(t("notAllRecipientsError"));
      return;
    }
    if (parsedInstant?.formFields.length === 0 || !parsedInstant) {
      toastError(t("noPlaceholdersError"));
      return;
    }
    if (size > MAX_INSTANT_JSON_SIZE) {
      toastError(t("instantSizeError"));
      return;
    }
    if (draftId && parsedInstant) {
      const res = await SharedDocuments.updateDraft(draftId, {
        name: tempName,
        recipients: recipients.map(({ firstName, lastName, email }) => ({
          firstName,
          lastName,
          email,
        })),
        instantJson: parsedInstant,
      });
      if (res?.id) {
        dispatch(setIsRequestReady(true));
        navigate(`${PATHES.EMAIL_DETAILS}/${draftId}`);
        toastSuccess(t("toastDraftSaveSuccess"));
      }
    }
  };

  const handleOpenDeleteDraftModal = () => {
    dispatch(setIsDeleteDraftModal(true));
  };

  const handleOpenAddRecipientModal = () => {
    setIsRecipientModal(true);
  };

  const handleCloseAddRecipientModal = async () => {
    setIsRecipientModal(false);
    await sleep(300);
    setEditedRecipient(null);
    setEditedRecipientIndex(0);
    await sleep(300);
    draftId && dispatch(getDraft(draftId));
  };

  const handleOpenEditRecipientModal = (
    recipient: IRecipient,
    index: number,
  ) => {
    setEditedRecipient(recipient);
    setEditedRecipientIndex(index);
    setIsRecipientModal(true);
  };

  if (!editedDraft) {
    return <></>;
  }

  // const getRecipientColor = (recipientEmail: string) => {
  //   const savedJSON = getSavedJSON();
  //   const recipientAnnotation = savedJSON?.annotations
  //     ?.filter((el: any) => el.backgroundColor)
  //     ?.find((el: any) => el.recipient === recipientEmail);
  //   return recipientAnnotation?.backgroundColor;
  // };

  return (
    <nav
      className={cs([styles.RequestWidget, isMinimized && styles.minimized])}
    >
      <Modal isShowed={isRecipientModal}>
        <AddRecipientModal
          onClose={handleCloseAddRecipientModal}
          onChange={onChange}
          recipient={editedRecipient}
          recipientIndex={editedRecipientIndex}
        />
      </Modal>
      <div
        className={cs([styles.resizer, isResizer && styles.visible])}
        onClick={() => setIsMinimized((prevState) => !prevState)}
        onMouseEnter={() => setIsResizer(true)}
        onMouseLeave={() => setIsResizer(false)}
      >
        <Icon
          name="chevron-left-empty"
          size={20}
          className={cs([styles.arrowIcon, isMinimized && styles.rotated])}
        />
      </div>
      {isEditName ? (
        <Input
          value={tempName}
          onChange={handleChangeName}
          onBlur={handleLeaveName}
          className={styles.fileInput}
          inputClassName={styles.originalInput}
          isBoundless
        />
      ) : (
        <h2 className={styles.title} onClick={hadleClickName}>
          {tempName}
        </h2>
      )}
      <div className={styles.info}>
        <div>
          {!isMinimized && <span>{t("draftCreated")}: </span>}
          <span className={styles.infoContent}>
            {dayjs(editedDraft.createdAt).format(
              `${dateFormat}, ${timeFormat}`,
            )}
          </span>
        </div>
        <div className={styles.infoItem}>
          {t("id")}: <DocIDCopy id={editedDraft.id} width="22.5rem" size="sm" />
        </div>
      </div>
      <div className={styles.recipients}>
        {!isMinimized && (
          <h2 className={styles.subtitle}>{t("recipients")}:</h2>
        )}
        <ul
          className={cs([
            styles.recipientsList,
            isMinimized && styles.minimized,
          ])}
        >
          {recipients?.map((item, index) => {
            // const getColor = getRecipientColor(item.email);
            // const diff = pspdfkitColorsList.length - filteredColors.length;
            // const withDiffColor = filteredColors[index - diff]?.standart;
            // const indexColor = filteredColors[index]?.standart;
            // const recipientColor = getColor || withDiffColor || indexColor;
            return (
              <li className={styles.recipient} key={item.email}>
                <div
                  onClick={() => dispatch(setActiveRecipient(item))}
                  className={cs([
                    styles.card,
                    item.email === activeRecipient?.email && styles.active,
                    isMinimized && styles.minimized,
                  ])}
                  style={{
                    backgroundColor: usedColors.find(
                      (el) => el.recipient === item.email,
                    )?.color,
                  }}
                >
                  <div className={styles.name}>
                    {item.firstName} {item.lastName}
                  </div>
                  {!isMinimized && (
                    <div className={styles.email}>{item.email}</div>
                  )}
                </div>
                <Icon
                  name="edit"
                  size={18}
                  action={() => handleOpenEditRecipientModal(item, index)}
                  className={styles.editButton}
                />
              </li>
            );
          })}
          {recipients.length < 10 && (
            <Button
              variant="textBlack"
              size="sm"
              title={isMinimized ? "" : t("addButtonTitle")}
              onClick={handleOpenAddRecipientModal}
              iconStart="add-recepient"
              iconStartType="stroke"
              className={isMinimized ? styles.addButton : ""}
            />
          )}
        </ul>
      </div>
      <Button
        variant="primary"
        title={t("submitButtonTitle")}
        onClick={handleGoNext}
        className={styles.submitButton}
        isDisabled={!tempName}
      />
      <Button
        variant="textBlack"
        className={styles.deleteButton}
        title={isMinimized ? "" : t("deleteButtonTitle")}
        iconStart={isMinimized ? "trash" : undefined}
        iconStartType="stroke"
        onClick={handleOpenDeleteDraftModal}
      />
    </nav>
  );
};
