import { FC, useState, ChangeEvent, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector, auth, settings } from "store";
import {
  setForgotEmail,
  setVerificationId as setAuthVerificationId,
} from "store/auth";
import {
  setVerificationId,
  setEmail as settingsSetEmail,
} from "store/settings";
import { Auth, Settings, User } from "api";

import { Input, Button } from "components/UI";
import { PATHES } from "constants/pathes";
import { validateEmail } from "utils";

import styles from "./styles.module.scss";

export const ForgotPassword: FC = () => {
  const { editableEmail } = useAppSelector(auth);
  const { passwordVerificationId, verificationId: settingsVerificationId } =
    useAppSelector(settings);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Auth", { keyPrefix: "ForgotPassword" });
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");

  const checkValidity = () => {
    const emailValidationErrors = email && validateEmail(email);
    if (emailValidationErrors) {
      setError(emailValidationErrors[0]);
    }
  };

  const handleChangeEmail = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setError("");
    setEmail(value);
  };

  const handleClearEmail = () => {
    setError("");
    setEmail("");
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    checkValidity();
    if (editableEmail === "private" && !error) {
      const res = await User.verifySetupEmail({
        email,
      });
      if (res?.verificationId) {
        dispatch(setAuthVerificationId(res?.verificationId));
        dispatch(settingsSetEmail(email));
        navigate(PATHES.ENTER_CODE);
      }
    }
    if (editableEmail === "old" && passwordVerificationId && !error) {
      const res = await Settings.verifyCurrentEmail({
        email,
        verificationId: passwordVerificationId,
      });
      if (res?.verificationId) {
        dispatch(setVerificationId(res?.verificationId));
        dispatch(settingsSetEmail(email));
        navigate(PATHES.ENTER_CODE);
      }
    }
    if (editableEmail === "new" && passwordVerificationId && !error) {
      const res = await Settings.verifyNewEmail({
        email,
        verificationId: settingsVerificationId,
      });
      if (res?.verificationId) {
        dispatch(setVerificationId(res?.verificationId));
        dispatch(settingsSetEmail(email));
        navigate(PATHES.ENTER_CODE);
      }
    }
    if (email && !error && !editableEmail) {
      dispatch(setForgotEmail(email));
      const res = await Auth.forgotPassword({
        email,
      });
      res?.message && navigate(PATHES.ENTER_CODE);
    }
  };

  return (
    <form className={styles.ForgotPassword} onSubmit={handleSubmit}>
      <h1 className={styles.title}>
        {t("title", {
          editableEmail:
            editableEmail && editableEmail !== "private"
              ? t(editableEmail)
              : "",
        })}
      </h1>
      {editableEmail === "private" ? (
        <>
          <h2 className={styles.subtitle}>{t("privateSubtitle")}</h2>
          <p className={styles.privateInfo}>{t("privateInfo")}</p>
        </>
      ) : (
        <p className={styles.info}>{t("provideEmail")}</p>
      )}
      <Input
        type="email"
        onChange={handleChangeEmail}
        onBlur={checkValidity}
        value={email}
        name="email"
        error={error}
        iconEnd={email ? "close-in-circle" : undefined}
        iconEndType="stroke"
        iconEndAction={handleClearEmail}
        placeholder={t("inputPlaceholder")}
      />
      <Button
        variant="primary"
        title={t("buttonTitle")}
        onClick={handleSubmit}
        isDisabled={!email || !!error}
        className={styles.submitButton}
      />
    </form>
  );
};
