import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./assets/locales/en.json";
import fr from "./assets/locales/fr.json";
import de from "./assets/locales/de.json";
import es from "./assets/locales/es.json";
import pl from "./assets/locales/pl.json";
import pt from "./assets/locales/pt.json";
import uk from "./assets/locales/uk.json";
import he from "./assets/locales/he.json";
import it from "./assets/locales/it.json";

const resources = {
  en,
  fr,
  de,
  es,
  pl,
  pt,
  uk,
  he,
  it,
};

i18n.use(initReactI18next).init({
  resources,
  // lng: "en",
  // fallbackLng: "en",
  // defaultNS: "en",
  fallbackNS: "en",
  interpolation: {
    escapeValue: false,
  },
});

i18n.addResourceBundle("en", "en", en);
i18n.addResourceBundle("fr", "fr", fr);
i18n.addResourceBundle("de", "de", de);
i18n.addResourceBundle("es", "es", es);
i18n.addResourceBundle("pl", "pl", pl);
i18n.addResourceBundle("pt", "pt", pt);
i18n.addResourceBundle("uk", "uk", uk);
i18n.addResourceBundle("he", "he", he);
i18n.addResourceBundle("it", "it", it);

export default i18n;

export const ns = ["en", "fr", "de", "es", "pl", "pt", "uk", "he", "it"];
