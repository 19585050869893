import { SelectedType } from "types";

import i18next, { ns } from "../i18n";

type FolderItem = {
  value: SelectedType;
  title: string;
};

export const getFolderItems = (): FolderItem[] => {
  const { t } = i18next;
  return [
    {
      title: t("Utils.FolderItems.certifiedFiles", { ns }),
      value: "PDF_CERTIFIED",
    },
    {
      title: t("Utils.FolderItems.originalFiles", { ns }),
      value: "PDF",
    },
    {
      title: t("Utils.FolderItems.folders", { ns }),
      value: "FOLDER",
    },
  ];
};

export const getFolderItemName = () => {
  const { t } = i18next;
  return {
    PDF_CERTIFIED: t("Utils.FolderItems.certifiedFile", { ns }),
    PDF: t("Utils.FolderItems.originalFile", { ns }),
    FOLDER: t("Utils.FolderItems.folder", { ns }),
  };
};

export const getRootFolderName = (folderName?: string) => {
  const modifiedName = folderName === "My documents" ? "My docs" : folderName;
  return modifiedName || "";
};
