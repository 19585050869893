import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate, Link } from "react-router-dom";
import fileDownload from "js-file-download";
import axios from "axios";

import { SharedDocuments } from "api";
import { useAppDispatch, useAppSelector, signings } from "store";
// import { setIsCompletedFileProcessing } from "store/signings";
import { getGuestRecipientDoc } from "store/signings/thunks";

import { PATHES } from "constants/pathes";
import { Button } from "components/UI";

import manJumping from "assets/img/man-jumping.svg";
import manHi from "assets/img/man-hi-black.svg";
import mainLogoBlack from "assets/img/main-logo-black.svg";

import styles from "./styles.module.scss";

export const GuestDocReady: FC = () => {
  const { linkId } = useParams();
  const {
    guestRecipientDoc,
    // isCompletedFileProcessing
  } = useAppSelector(signings);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("GuestDocReady");

  useEffect(() => {
    if (linkId) {
      dispatch(getGuestRecipientDoc(linkId));
    }
  }, [dispatch, linkId]);

  // useEffect(() => {
  //   if (isCompletedFileProcessing) {
  //     const timer = setTimeout(() => {
  //       dispatch(setIsCompletedFileProcessing(false));
  //     }, 3000);
  //     return () => clearTimeout(timer);
  //   }
  // }, [dispatch, isCompletedFileProcessing]);

  const handleDownload = async () => {
    if (linkId && guestRecipientDoc) {
      const res = await SharedDocuments.downloadGuestRecipientDoc({
        id: linkId,
      });

      if (res?.file?.url && guestRecipientDoc) {
        axios
          .get(res?.file?.url, {
            responseType: "blob",
          })
          .then(async (res) => {
            fileDownload(res.data, `${guestRecipientDoc?.name}.pdf`);
          });
      }
    }
  };

  const handleOpenAposti = () => {
    navigate(PATHES.HOME);
  };

  const handleOpenSignedDoc = () => {
    localStorage.removeItem("instantJSON");
    sessionStorage.removeItem("IsSigningStarted");
    navigate(`${PATHES.GUEST_RECIPIENT_DOCUMENT}/${linkId}`);
  };

  const renderDownloadLink = () => (
    <span className={styles.downloadLink} onClick={handleDownload}>
      {t("downloadLink")}
    </span>
  );

  const renderOpenApostiButton = () => (
    <Button
      variant="primary"
      size="lg"
      title={t("openAposti")}
      onClick={handleOpenAposti}
      className={styles.button}
    />
  );

  const renderOpenSignedDocButton = () => (
    <Button
      variant="secondary"
      size="lg"
      title={t("openSignedDoc")}
      onClick={handleOpenSignedDoc}
      className={styles.button}
    />
  );

  const renderOpenDocButton = () => (
    <Button
      variant="secondary"
      size="lg"
      title={t("openDoc")}
      onClick={handleOpenSignedDoc}
      className={styles.button}
    />
  );

  const getStatusInfo = () => {
    switch (guestRecipientDoc?.status) {
      case "WAITING_FOR_OTHERS":
        return {
          img: manJumping,
          imgName: "manJumping",
          title: t("titleWaiting"),
          info: t("checkIt"),
          isDownload: true,
          buttons: (
            <div className={styles.buttons}>
              {renderOpenApostiButton()}
              {renderOpenSignedDocButton()}
            </div>
          ),
        };
      case "COMPLETED":
        return {
          img: manJumping,
          imgName: "manJumping",
          title: t("titleComplete"),
          info: t("checkIt"),
          isDownload: true,
          buttons: (
            <div className={styles.buttons}>
              {renderOpenApostiButton()}
              {renderOpenSignedDocButton()}
            </div>
          ),
        };
      case "REJECTED":
        return {
          img: manHi,
          imgName: "manHi",
          title: t("titleReject"),
          info: t("rejectInfo"),
          isDownload: false,
          buttons: (
            <div className={styles.buttons}>{renderOpenApostiButton()}</div>
          ),
        };
      case "CANCELED":
      case "EXPIRED":
        return {
          img: manHi,
          imgName: "manHi",
          title: t("titleVoid"),
          info: t("voidInfo"),
          isDownload: false,
          buttons: (
            <div className={styles.buttons}>
              {renderOpenApostiButton()}
              {renderOpenDocButton()}
            </div>
          ),
        };

      default:
        return {
          img: manHi,
          imgName: "manHi",
          title: t("titleNotFound"),
          info: t("notFoundInfo"),
          isDownload: false,
          buttons: (
            <div className={styles.buttons}>{renderOpenApostiButton()}</div>
          ),
        };
    }
  };

  return (
    <div className={styles.GuestDocReady}>
      <div className={styles.wrap}>
        <Link to={PATHES.SIGN_IN} className={styles.linkToMain}>
          <img src={mainLogoBlack} alt="main-logo" width="124" height="33" />
        </Link>
        <main className={styles.main}>
          <img
            src={getStatusInfo().img}
            alt={getStatusInfo().imgName}
            width={350}
            height={380}
          />
          <h2 className={styles.title}>{getStatusInfo().title}</h2>
          {/* {!isCompletedFileProcessing && ( */}
          <p className={styles.info}>
            {getStatusInfo().info}{" "}
            {getStatusInfo().isDownload && renderDownloadLink()}
          </p>
          {/* )} */}
          {getStatusInfo().buttons}
        </main>
      </div>
    </div>
  );
};
