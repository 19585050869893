import { FC } from "react";
import { pdfjs, Document, Page } from "react-pdf";

import { SelectedType } from "types";

import { Icon } from "components/UI";

import folderEmpty from "assets/img/icons/folder-empty-big.svg";
import filePreview from "assets/img/file_preview.png";

import styles from "./styles.module.scss";

type DocImageProps = {
  type: SelectedType;
  previewUrl?: string;
  isPdf?: boolean;
};

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export const DocImage: FC<DocImageProps> = ({ type, previewUrl, isPdf }) => {
  return (
    <div className={styles.DocImage}>
      {type === "PDF_CERTIFIED" && (
        <Icon name="certification" className={styles.certified} />
      )}
      {isPdf ? (
        <Document file={previewUrl} className={styles.pdfPreview}>
          <Page
            pageNumber={1}
            width={40}
            height={40}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        </Document>
      ) : (
        <img
          className={styles.img}
          src={type === "FOLDER" ? folderEmpty : previewUrl || filePreview}
          alt={type}
        />
      )}
    </div>
  );
};
